import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
export default function PopModal(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <>
      <Button onClick={onOpen}>{props.Icon}</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.head}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              {/* <Input
                ref={initialRef}
                placeholder="First name"
                defaultValue={props.name}
              /> */}
              {props.name}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              {props.email}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Phone Number</FormLabel>

              {props.mobile}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              {props.role}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                props.handleUpdate(onClose, props.id);
              }}
              disabled={props.error || props.emailError ? true : false}
            >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
