import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Input,
  Select,
  useToast,
  Stack,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import {
  DeleteIcon,
  EditIcon,
  AddIcon,
  NotAllowedIcon,
} from "@chakra-ui/icons";
import AddModal from "./addModal";
import EditModal from "./editModal";
import PopDelete from "./deletePopover";
// Custom components
import Card from "components/card/Card";
import UserApi from "api/user";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
// import Menu from "components/menu/MainMenu";
import { useAuth } from "../../../../auth-context/auth.context";
export default function CheckTable(props) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneNumberRegex = /^\d{10}$/;
  const { user } = useAuth();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  //Data Fetching
  // const [data, setData] = useState([]);
  const [isError, setIsError] = useState("");
  const [list, setList] = useState([]);
  const [token, setToken] = useState(user.token);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [role, setRole] = useState();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState([""]);
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const fetchList = async () => {
    try {
      setLoading(true);
      let response = await UserApi.UserList({
        token,
      });
      response = response.data;
      setLoading(false);
      let responseData = response.responseData;
      console.log(responseData);
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setList(responseData.reverse());
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchList();
  }, []);
  // modal
  useEffect(() => {
    if (list) {
      setTotalPages(Math.ceil(list.length / itemsPerPage));
    }
  }, [list, itemsPerPage]);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Button
        key={i}
        size="sm"
        variant="ghost"
        colorScheme={i === currentPage ? "blue" : undefined}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Button>
    );
  }
  const handleSearch = async (search) => {
    setSearchTerm(search);
    setCurrentPage(1); // Reset currentPage to 1 on each search
  };

  const filteredList = list.filter((user) =>
    [user.name, user.email, user.phoneNumber, user.role].some(
      (field) => field && field.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredList.slice(startIndex, endIndex);

  const handleDelete = async (e) => {
    try {
      const id = [e.target.value];
      const response = await UserApi.UserDelete({ id, token });
      console.log(response.data);
      if (response.data.statusCode === 1) {
        toast({
          title: "Successfully Deleted",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      } else if (response.data.statusCode !== 1) {
        let error = response.data.error.errorMessage;
        toast({
          title: error,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
      fetchList();
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdate = async (onClose, id) => {
    try {
      const response = await UserApi.UserUpdate({
        id,
        name,
        email,
        phoneNumber,
        role,
        token,
      });
      console.log(response.data);
      if (response.data.statusCode == 1) {
        toast({
          title: "Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        onClose();
        fetchList();
      } else if (response.data.statusCode == 0) {
        let error = response.data.responseData.error.name;
        toast({
          title: error,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      mt="7rem"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Users
        </Text>
        <Flex gap={4}>
          <Input
            type="text"
            placeholder="Search User.."
            onChange={(e) => handleSearch(e.target.value)}
          />
          <AddModal Icon={<AddIcon />} head={"Add User"} func={fetchList} />
        </Flex>
      </Flex>
      {loading && (
        <Text fontSize="xl" fontWeight="700" mt={4} p={4}>
          Loading....
        </Text>
      )}
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Sr.no
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Name
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Email
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Mobile
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Role
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Status
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Actions
              </Flex>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {currentItems.map((user, index) => {
            return (
              <Tr key={user._id}>
                <Td>
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                    >
                      {startIndex + index + 1}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                    >
                      {user.name}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {" "}
                    {user.email}
                  </Text>
                </Td>
                <Td>
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {" "}
                    {user.phoneNumber}
                  </Text>
                </Td>
                <Td>
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {user.role}
                  </Text>
                </Td>
                <Td>
                  <Button colorScheme="blue" size="sm">
                    {user.isDeleted === false ? "Active" : "Deactive"}
                  </Button>
                </Td>
                <Td>
                  <Flex justify="space-around">
                    <EditModal
                      Icon={<EditIcon />}
                      head={"Edit User"}
                      name={
                        <Input
                          type="text"
                          defaultValue={user.name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      }
                      email={
                        <Input
                          type="email"
                          defaultValue={user.email}
                          sInvalid={emailError}
                          onChange={(e) => {
                            const input = e.target.value;
                            setEmail(input);
                            if (!emailRegex.test(input)) {
                              setEmailError(true);
                            } else {
                              setEmailError(false);
                            }
                          }}
                        />
                      }
                      mobile={
                        <Input
                          isInvalid={error}
                          placeholder="Phone Number"
                          defaultValue={user.phoneNumber}
                          onChange={(e) => {
                            const input = e.target.value;
                            setPhoneNumber(input);

                            if (!phoneNumberRegex.test(input)) {
                              setError(true);
                            } else {
                              setError(false);
                            }
                          }}
                        />
                      }
                      role={
                        <Select
                          placeholder="Select option"
                          defaultValue={user.role}
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="admin">Admin</option>
                          <option value="superadmin">SuperAdmin</option>
                          <option value="instructor">Instructor</option>
                          <option value="student">Student</option>
                          <option value="seoExpert">SEO Expert</option>
                        </Select>
                      }
                      id={user._id}
                      handleUpdate={handleUpdate}
                      error={error}
                      emailError={emailError}
                    />
                    {user.isDeleted === false ? (
                      <PopDelete
                        Icon={<DeleteIcon />}
                        button={
                          <Button
                            colorScheme="red"
                            onClick={handleDelete}
                            value={user._id}
                          >
                            Delete
                          </Button>
                        }
                      />
                    ) : (
                      "Disabled"
                    )}
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {/* display the pagination */}
      <Stack direction="row" spacing={2} mt={4} justifyContent="center">
        <Box>
          <Button
            size="sm"
            isDisabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            Previous
          </Button>
        </Box>

        {pageItems}

        <Box>
          <Button
            size="sm"
            isDisabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </Button>
        </Box>
      </Stack>
      {isError !== "" && <h2>{isError}</h2>}
    </Card>
  );
}
