import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
  Text,
  Flex,
} from "@chakra-ui/react";

export default function PopModal(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <>
      <Flex onClick={onOpen}>{props.Icon}</Flex>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.head}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt="5">
              <FormLabel>Name</FormLabel>
              <Text fontSize="xl">{props.name}</Text>
            </FormControl>
            <FormControl mt="5">
              <FormLabel>Email</FormLabel>
              <Text fontSize="xl">{props.email}</Text>
            </FormControl>
            <FormControl mt="5">
              <FormLabel>Mobile</FormLabel>
              <Text fontSize="xl">{props.mobile}</Text>
            </FormControl>
            <FormControl mt="5">
              <FormLabel>Query</FormLabel>
              <Text fontSize="xl">{props.query}</Text>
            </FormControl>
            <FormControl mt="5">
              <FormLabel>Attachment</FormLabel>
              {props.download}
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
