import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  Select,
  useToast,
} from "@chakra-ui/react";
import DiscountApi from "api/discount";
import { useAuth } from "../../../../auth-context/auth.context";
export default function PopModal(props) {
  const toast = useToast();
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [couponType, setCouponType] = useState();
  const [couponCode, setCouponCode] = useState();
  const [discountValue, setDiscountValue] = useState();
  const [maxConsume, setMaxConsume] = useState();
  const [expireDate, setExpireDate] = useState();
  const [description, setDescription] = useState();
  const [token, setToken] = useState(user.token);
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  const addUser = async () => {
    if (
      !couponType ||
      !couponCode ||
      !discountValue ||
      !maxConsume ||
      !expireDate ||
      !description
    ) {
      toast({
        title: "All fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        const response = await DiscountApi.DiscountCreate({
          couponType,
          couponCode,
          discountValue,
          maxConsume,
          expireDate,
          description,
          token,
        });
        console.log(response.data);
        if (!response.data.responseData.message) {
          toast({
            title: "Successfully Added",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          onClose();
          props.func();
        } else {
          toast({
            title: response.data.responseData.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  const date = `${year}-${month}-${day}`;
  return (
    <>
      <Button onClick={onOpen}>{props.Icon}</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.head}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                placeholder="Select option"
                onChange={(e) => setCouponType(e.target.value)}
              >
                <option value="fixed">Fixed</option>
              </Select>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Coupon Code</FormLabel>
              <Input
                placeholder="Upto 30 Characters"
                maxLength="30"
                type="text"
                onChange={(e) => setCouponCode(e.target.value)}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder="Upto 100 Characters"
                maxLength="100"
                type="text"
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Max Consume</FormLabel>
              <Input
                type="number"
                placeholder="Number of consumption"
                onChange={(e) => setMaxConsume(e.target.value)}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Discount Value</FormLabel>
              <Input
                type="number"
                placeholder="Discount Price"
                onChange={(e) => setDiscountValue(e.target.value)}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Expires On</FormLabel>
              <Input
                type="date"
                placeholder="Expiry Date"
                onKeyDown={handleKeyDown}
                min={date}
                onChange={(e) => setExpireDate(e.target.value)}
                required
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={addUser}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
