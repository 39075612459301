import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Input,
  Select,
  useToast,
  Stack,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { DeleteIcon, EditIcon, AddIcon } from "@chakra-ui/icons";
import AddModal from "./components/addModal";
import EditModal from "./components/editModal";
import PopDelete from "./components/deletePopover";
// Custom components
import Card from "components/card/Card";
import DiscountApi from "api/discount";
// import Menu from "components/menu/MainMenu";
import { useAuth } from "../../../auth-context/auth.context";
export default function Index(props) {
  const { user } = useAuth();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const toast = useToast();
  //Data Fetching
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState("");
  const [list, setList] = useState([]);
  // const [token, setToken] = useState(user.token);
  const [id, setId] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [couponType, setCouponType] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [maxConsume, setMaxConsume] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [description, setDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  const fetchList = async () => {
    const token = user.token;
    try {
      setLoading(true);
      let response = await DiscountApi.DiscountList({
        token,
      });
      response = response.data;
      setLoading(false);
      let responseData = response.responseData;
      console.log(response, "res");
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      }
      if (response.statusCode === 1 && responseData !== undefined) {
        const filter = responseData.filter(
          (item) => item.createdBy === user._id
        );
        console.log(filter, "list");
        setList(filter.reverse());
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(async () => {
    fetchList();
  }, []);
  // modal
  useEffect(() => {
    if (list) {
      setTotalPages(Math.ceil(list.length / itemsPerPage));
    }
  }, [list, itemsPerPage]);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Button
        key={i}
        size="sm"
        variant="ghost"
        colorScheme={i === currentPage ? "blue" : undefined}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Button>
    );
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = list?.slice(startIndex, endIndex);
  const handleDelete = async (e) => {
    e.preventDefault();
    const token = user.token;
    try {
      const id = [e.target.value];
      const response = await DiscountApi.DiscountDelete({ id, token });
      console.log(response.data);
      if (response.data.statusCode === 1) {
        toast({
          title: "Successfully Deleted",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        fetchList();
      } else if (response.data.statusCode !== 1) {
        const error = response.data.error.errorMessage;
        toast({
          title: error,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdate = async (onClose, id) => {
    const token = user.token;
    try {
      const response = await DiscountApi.DiscountUpdate({
        id,
        token,
        couponType,
        couponCode,
        discountValue,
        maxConsume,
        expireDate,
        description,
      });
      console.log(response.data);
      if (response.data.statusCode === 1) {
        toast({
          title: "Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        onClose();
        fetchList();
      } else if (response.data.statusCode !== 1) {
        const error = response.data.error.errorMessage;
        toast({
          title: error,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
      // window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  const date = `${year}-${month}-${day}`;
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      mt="7rem"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Coupons
        </Text>

        <AddModal Icon={<AddIcon />} head={"Add Coupon"} func={fetchList} />
      </Flex>
      {loading && (
        <Text fontSize="xl" fontWeight="700" mt={4} p={4}>
          Loading....
        </Text>
      )}
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Sr no
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Type
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Coupon Code
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Description
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Max Consume
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Discount Value
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Exipires On
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Actions
              </Flex>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {currentItems?.map((coupon, index) => {
            return (
              <Tr key={coupon._id}>
                <Td>
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                    >
                      {startIndex + index + 1}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                    >
                      {coupon.couponType}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                    >
                      {coupon.couponCode}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                    >
                      {coupon.description}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {" "}
                    {coupon.maxConsume}
                  </Text>
                </Td>
                <Td>
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {coupon.discountValue}
                  </Text>
                </Td>
                <Td>
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {coupon.expireDate?.slice(0, 10)}
                  </Text>
                </Td>
                <Td>
                  <Flex justify="space-around">
                    <EditModal
                      Icon={<EditIcon />}
                      head={"Edit Coupon"}
                      couponType={
                        <Select
                          placeholder="Select option"
                          defaultValue={coupon.couponType}
                          onChange={(e) => setCouponType(e.target.value)}
                        >
                          <option value="fixed">Fixed</option>
                        </Select>
                      }
                      couponCode={
                        <Input
                          placeholder="Upto 30 Characters"
                          maxLength="30"
                          type="text"
                          onChange={(e) => setCouponCode(e.target.value)}
                          required
                          defaultValue={coupon.couponCode}
                        />
                      }
                      description={
                        <Input
                          placeholder="Upto 100 Characters"
                          maxLength="100"
                          type="text"
                          onChange={(e) => setDescription(e.target.value)}
                          required
                          defaultValue={coupon.description}
                        />
                      }
                      maxConsume={
                        <Input
                          type="number"
                          placeholder="Number of consumption"
                          onChange={(e) => setMaxConsume(e.target.value)}
                          required
                          defaultValue={coupon.maxConsume}
                        />
                      }
                      discountValue={
                        <Input
                          type="number"
                          placeholder="Discount Price"
                          onChange={(e) => setDiscountValue(e.target.value)}
                          required
                          defaultValue={coupon.discountValue}
                        />
                      }
                      expiresOn={
                        <Input
                          type="date"
                          placeholder="Expiry Date"
                          onKeyDown={handleKeyDown}
                          min={date}
                          onChange={(e) => setExpireDate(e.target.value)}
                          required
                          defaultValue={coupon.expireDate?.slice(0, 10)}
                        />
                      }
                      id={coupon._id}
                      handleUpdate={handleUpdate}
                    />
                    {}
                    <PopDelete
                      Icon={<DeleteIcon />}
                      button={
                        <Button
                          colorScheme="red"
                          onClick={handleDelete}
                          value={coupon._id}
                        >
                          Delete
                        </Button>
                      }
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Stack direction="row" spacing={2} mt={4} justifyContent="center">
        <Box>
          <Button
            size="sm"
            isDisabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            Previous
          </Button>
        </Box>

        {pageItems}

        <Box>
          <Button
            size="sm"
            isDisabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </Button>
        </Box>
      </Stack>
      {isError !== "" && <h2>{isError}</h2>}
    </Card>
  );
}
