import React, { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
// import { uploadFile } from "react-s3";
// import { Buffer } from "buffer";
import AWS from "aws-sdk";
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  Flex,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Box,
  Button,
  useToast,
  Progress,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormHelperText,
} from "@chakra-ui/react";

import TagsInput from "./Tags";
import Card from "components/card/Card";
import CourseApi from "../../../../api/course";
import { AddIcon, DeleteIcon, EditIcon, LockIcon } from "@chakra-ui/icons";
import AddLecture from "./lecturemodal";
import SectionModal from "./sectionmodal";
import { useAuth } from "../../../../auth-context/auth.context";
import { useHistory } from "react-router-dom";

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function AddCourse() {
  const history = useHistory();
  function handleClick() {
    history.push("admin/courses");
  }
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  const toast = useToast();
  const { user } = useAuth();
  const [tags, setTags] = useState();
  const selectedTags = (tags) => setTags(tags);
  const editor = useRef(null);
  const [totalhours, setTotalhours] = useState("");
  const [language, setLanguage] = useState("");
  const [totallectures, setTotallectures] = useState("");
  const [picLoading, setPicLoading] = useState(false);
  const [vidLoading, setVidLoading] = useState(false);
  const [isname, setIsname] = useState(false);
  const [istype, setIstype] = useState(false);
  const [isShortdescriptionEmpty, setIsShortdescriptionEmpty] = useState(false);
  const [isdesc, setIsdesc] = useState(false);
  const [islang, setIslang] = useState(false);
  const [istag, setIstag] = useState(false);
  const [istp, setIstp] = useState(false);
  const [isbp, setIsbp] = useState(false);
  const [isreq, setIsreq] = useState(false);
  const [isobj, setIsobj] = useState(false);
  const [isicon, setIsicon] = useState(false);
  const [ismed, setIsmed] = useState(false);
  const [isth, setIsth] = useState(false);
  const [istl, setIstl] = useState(false);
  const [istime, setIstime] = useState(false);
  const [isWebd, setIsWebd] = useState(false);
  const [isWebt, setIsWebt] = useState(false);
  const [lecLoading, setLecLoading] = useState(false);
  const [updateKey, setUpdateKey] = useState(0);
  const [section, setSection] = useState([]);
  const [lecturename, setLecturename] = useState([]);
  const [sectionname, setSectionname] = useState([]);
  const [videourl, setVideourl] = useState();
  const [vidprivate, setVidprivate] = useState("true");
  const [objectives, setObjectives] = useState([""]);
  const [requirements, setRequirements] = useState([""]);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [shortdescription, setShortdescription] = useState("");
  const [description, setDescription] = useState("");
  const [webinarDate, setWebinarDate] = useState("");
  const [webinarTime, setWebinarTime] = useState();
  const [timeError, setTimeError] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [webDay, setWebDay] = useState("");
  const [isday, setIsday] = useState(false);
  const [webinarTimeMins, setWebinarTimeMins] = useState();
  const [timeMinsError, setTimeMinsError] = useState(false);
  const [isWebtm, setIsWebtm] = useState(false);
  // const [editSection, setEditSection] = useState();
  // const [isEditing, setIsEditing] = useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [newName, setNewName] = useState("");
  const [newVideourl, setNewVideourl] = useState("");
  const [newPrivate, setNewPrivate] = useState("");
  const [newDuration, setNewDuration] = useState("");
  const [progress, setProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(null);
  const cancelFileUpload = useRef(null);
  const [selectedSectionId, setSelectedSectionId] = useState();
  const [selectedLectureId, setSelectedLectureId] = useState();
  const [baseError, setBaseError] = useState(false);
  const [totalError, setTotalError] = useState(false);
  const [lecError, setLecError] = useState(false);
  const [durError, setDurError] = useState(false);
  // const [editSectionId, setEditSectionId] = useState(null);
  // const [isEditing, setIsEditing] = useState(false);
  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();
  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();
  const {
    isOpen: isOpenModal3,
    onOpen: onOpenModal3,
    onClose: onCloseModal3,
  } = useDisclosure();
  const {
    isOpen: isOpenModal4,
    onOpen: onOpenModal4,
    onClose: onCloseModal4,
  } = useDisclosure();

  const [icon, setIcon] = useState("");
  // const [image, setImage] = useState();
  const [media, setMedia] = useState("");
  const [video, setVideo] = useState();

  const [basePrice, setBasePrice] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [status, setStatus] = useState("DRAFT");
  const [error, setError] = useState();
  const [token, setToken] = useState(user.token);
  const [batch1, setBatch1] = useState();
  const [batch2, setBatch2] = useState();
  const [batch3, setBatch3] = useState();
  const [batch4, setBatch4] = useState();
  const [isBatch1, setIsBatch1] = useState(false);
  const [isBatch2, setIsBatch2] = useState(false);
  const [isBatch3, setIsBatch3] = useState(false);
  const [isBatch4, setIsBatch4] = useState(false);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [newuploadStatus, setnewuploadStatus] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const id = null;
  const initialRef1 = useRef(null);
  const finalRef1 = useRef(null);
  const initialRef2 = useRef(null);
  const finalRef2 = useRef(null);
  const initialRef3 = useRef(null);
  const finalRef3 = useRef(null);
  const initialRef4 = useRef(null);
  const finalRef4 = useRef(null);
  function handleClickObj() {
    setObjectives([...objectives, ""]);
  }
  const handleDeleteObj = (index) => {
    const newObjectives = [...objectives];
    newObjectives.splice(index, 1);
    setObjectives(newObjectives);
  };
  function handleChangeObj(i, event) {
    const values = [...objectives];
    values[i] = event.target.value;
    setObjectives(values);
    console.log("obj", objectives);
  }
  function handleClickReq() {
    setRequirements([...requirements, ""]);
  }
  const handleDeleteReq = (index) => {
    const newRequirements = [...requirements];
    newRequirements.splice(index, 1);
    setRequirements(newRequirements);
  };
  function handleChangeReq(i, event) {
    const values = [...requirements];
    values[i] = event.target.value;
    setRequirements(values);
    console.log("req", requirements);
  }

  const handleSection = (id) => {
    setSection([
      ...section,
      { id: section.length, topicname: sectionname, subtopic: [] },
    ]);
    onCloseModal1();
    setSectionname("");

    console.log(section);
  };

  const handleEditSection = (sectionId, newSectionName) => {
    const updatedSections = section.map((sec) => {
      // console.log(id);

      if (sec.id === sectionId) {
        return {
          ...sec,
          topicname: newSectionName,
        };
      } else {
        return sec;
      }
    });
    setSection(updatedSections);
    onCloseModal2();
  };
  const handleSectionDelete = (id) => {
    const filteredSections = section.filter((sec) => sec.id !== id);
    setSection(filteredSections);
  };

  const handleLecture = (id) => {
    const updatedSection = section.map((div) => {
      if (div.id === id) {
        return {
          ...div,
          subtopic: [
            ...div.subtopic,
            {
              id: div.subtopic.length,
              name: lecturename,
              videourl: videourl,
              private: vidprivate,
              videoDuration: videoDuration,
            },
          ],
        };
      }
      return div;
    });
    setSection(updatedSection);
    setLecturename("");
    setVideourl("");
    setVidprivate("true");
    onCloseModal3();
  };
  const handleEditLecture = (
    id,
    lecture,
    newName,
    newVideourl,
    newPrivate,
    newDuration
  ) => {
    const updatedSection = section.map((sec) => {
      if (sec.id === id) {
        const updatedLectures = sec.subtopic.map((lec) => {
          if (lec.id === lecture) {
            return {
              ...lec,
              name: newName,
              videourl: newVideourl,
              private: newPrivate,
              videoDuration: newDuration,
            };
          } else {
            return lec;
          }
        });

        return {
          ...sec,
          subtopic: updatedLectures,
        };
      } else {
        return sec;
      }
    });
    setSection(updatedSection);
    setUpdateKey(updateKey + 1);
    onCloseModal4();
  };
  const handleDeleteLecture = (sectionId, lectureId) => {
    setSection(
      section.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            subtopic: section.subtopic.filter(
              (lecture) => lecture.id !== lectureId
            ),
          };
        } else {
          return section;
        }
      })
    );
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const S3_BUCKET = "stgbuck";
  const REGION = "ap-south-1";
  const ACCESS_KEY = "AKIA47CRXACIO2USR2VS";
  const SECRET_ACCESS_KEY = "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";

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
    httpOptions: {
      timeout: 12000000,
    },
  });
  const handleUpload = async (file) => {
    if (
      (file.type === "video/mp4" ||
        file.type === "video/mkv" ||
        file.type === "video/mpeg") &&
      file.size <= 1000 * 1024 * 1024
    ) {
      const videoPlayer = document.createElement("video");
      videoPlayer.src = URL.createObjectURL(file);
      videoPlayer.addEventListener("loadedmetadata", () => {
        setVideoDuration(videoPlayer.duration);
        setNewDuration(videoPlayer.duration);
        URL.revokeObjectURL(videoPlayer.src);
      });

      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: file.name,
        ContentDisposition: "inline",
        ContentType: "video/mp4",
      };
      setLecLoading(true);
      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err, data) => {
          if (err) {
            console.log(err);
            setLecLoading(false);
            setProgress(0);
          } else {
            console.log(data); // Handle the successful response here
            setLecLoading(false);
            setVideourl(
              `https://stgbuck.s3.ap-south-1.amazonaws.com/${file.name}`
            );
            setNewVideourl(
              `https://stgbuck.s3.ap-south-1.amazonaws.com/${file.name}`
            );
            setProgress(0);
          }
        });
    } else {
      toast({
        title:
          "Please Select a Video (Only mp4,mkv,mpeg are allowed and size limit is 1gb)",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
  };

  const handleFileInputChangeImage = (image) => {
    setPicLoading(true);
    if (image === undefined) {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (
      (image.type === "image/jpeg" || image.type === "image/png") &&
      image.size <= 10 * 1024 * 1024
    ) {
      const data = new FormData();
      data.append("file", image);
      fetch("https://api.stockventure.in/util/uploadmedia", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.responseData[0]);
          setIcon(data.responseData[0]);
          setPicLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPicLoading(false);
        });
    } else {
      toast({
        title:
          "Please Select a Image (Only png and jpg are allowed and size limit is 10mb)",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setPicLoading(false);
      return;
    }
  };
  const handleFileInputChangeVideo = (video) => {
    setVidLoading(true);
    if (video === undefined) {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    if (
      (video.type === "video/mp4" ||
        video.type === "video/mkv" ||
        video.type === "video/mpeg") &&
      video.size <= 500 * 1024 * 1024
    ) {
      const data = new FormData();
      data.append("file", video);
      fetch("https://api.stockventure.in/util/uploadmedia", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.responseData[0]);
          setMedia(data.responseData[0]);
          setVidLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setVidLoading(false);
        });
    } else {
      toast({
        title:
          "Please Select a Video (Only mp4,mkv,mpeg are allowed and size limit is 100mb)",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setVidLoading(false);
      return;
    }
  };
  const CourseDraft = async (e) => {
    e.preventDefault();
    if (!name) {
      setIsname(true);
    } else {
      setIsname(false);
    }
    if (!type) {
      setIstype(true);
    } else {
      setIstype(false);
    }
    if (!shortdescription) {
      setIsShortdescriptionEmpty(true);
    } else {
      setIsShortdescriptionEmpty(false);
    }
    if (!description) {
      setIsdesc(true);
    } else {
      setIsdesc(false);
    }

    if (!language) {
      setIslang(true);
    } else {
      setIslang(false);
    }
    if (!tags) {
      setIstag(true);
    } else {
      setIstag(false);
    }
    if (!objectives) {
      setIsobj(true);
    } else {
      setIsobj(false);
    }
    if (!requirements) {
      setIsreq(true);
    } else {
      setIsreq(false);
    }
    if (!icon) {
      setIsicon(true);
    } else {
      setIsicon(false);
    }
    if (!media) {
      setIsmed(true);
    } else {
      setIsmed(false);
    }
    if (!totalhours) {
      setIsth(true);
    } else {
      setIsth(false);
    }
    if (!totallectures) {
      setIstl(true);
    } else {
      setIstl(false);
    }
    if (!totalPrice) {
      setIstp(true);
    } else {
      setIstp(false);
    }
    if (!basePrice) {
      setIsbp(true);
    } else {
      setIsbp(false);
    }

    if (type === "live") {
      if (!batch1) {
        setIsBatch1(true);
      } else {
        setIsBatch1(false);
      }
      if (!batch2) {
        setIsBatch2(true);
      } else {
        setIsBatch2(false);
      }
      if (!batch3) {
        setIsBatch3(true);
      } else {
        setIsBatch3(false);
      }
      if (!batch4) {
        setIsBatch4(true);
      } else {
        setIsBatch4(false);
      }
    }

    if (
      !type ||
      !name ||
      !tags ||
      !objectives ||
      !requirements ||
      !shortdescription ||
      !description ||
      !language ||
      !icon ||
      !media ||
      !totalhours ||
      !totallectures ||
      !totalPrice ||
      !basePrice ||
      (!batch1 && type === "live") ||
      (!batch2 && type === "live") ||
      (!batch3 && type === "live") ||
      (!batch4 && type === "live") ||
      !status
    ) {
      toast({
        title: "All fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        let response = await CourseApi.CourseCreate({
          id,
          type,
          name,
          tags,
          section,
          objectives,
          requirements,
          shortdescription,
          description,
          meta: {
            totalhours,
            totallectures,
            language,
          },
          icon,
          media,
          totalPrice,
          basePrice,
          status,
          token,
          batch1,
          batch2,
          batch3,
          batch4,
        });
        response = response.data;
        console.log(response);
        if (response.statusCode === 1) {
          toast({
            title: "Successfully Added",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          history.push("admin/courses");
        } else if (response.statusCode === 0) {
          let error = response.error.name;
          toast({
            title: error,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      } catch (err) {
        if (err.message) {
          return setError(err.message);
        }
        return setError("There has been an error.");
      }
    }
  };
  const WebinarDraft = async () => {
    if (!name) {
      setIsname(true);
    } else {
      setIsname(false);
    }
    if (!description) {
      setIsdesc(true);
    } else {
      setIsdesc(false);
    }
    if (!totalPrice) {
      setIstp(true);
    } else {
      setIstp(false);
    }
    if (!basePrice) {
      setIsbp(true);
    } else {
      setIsbp(false);
    }
    if (!webinarDate) {
      setIsWebd(true);
    } else {
      setIsWebd(false);
    }
    if (!webinarTime) {
      setIsWebt(true);
    } else {
      setIsWebt(false);
    }
    if (!timeZone || timeError) {
      setIstime(true);
    }
    if (!icon) {
      setIsicon(true);
    } else {
      setIsicon(false);
    }
    if (!webinarTimeMins || timeMinsError) {
      setIsWebtm(true);
    }
    if (
      !name ||
      !description ||
      !totalPrice ||
      !basePrice ||
      !status ||
      !webinarDate ||
      !webinarTime ||
      !timeZone ||
      !icon ||
      !webinarTimeMins
    ) {
      toast({
        title: "Marked fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        let response = await CourseApi.CourseCreate({
          id,
          type,
          name,
          tags,
          description,
          totalPrice,
          basePrice,
          status,
          token,
          icon,
          meta: {
            webinarDate,
            webinarTime,
            timeZone,
            webinarTimeMins,
          },
          webinarDate,
          webinarTime,
        });
        response = response.data;
        console.log(response);
        if (response.statusCode === 1) {
          toast({
            title: "Successfully Added",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          history.push("admin/courses");
        } else if (response.statusCode === 0) {
          let error = response.error.name;
          toast({
            title: error,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      } catch (err) {
        if (err.message) {
          return setError(err.message);
        }
        return setError("There has been an error.");
      }
    }
  };
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  const date = `${year}-${month}-${day}`;
  const handleBasePrice = (base) => {
    const regex = /^\d{0,7}(\.\d{0,1})?$/;
    if (regex.test(base)) {
      setBasePrice(base);
      setBaseError(false);
    } else {
      setBaseError(true);
    }
  };
  const handleTotalPrice = (base) => {
    const regex = /^\d{0,7}(\.\d{0,1})?$/;
    if (regex.test(base)) {
      setTotalPrice(base);
      setTotalError(false);
    } else {
      setTotalError(true);
    }
  };
  const handleTotalhours = (base) => {
    const regex = /^\d{0,3}(\.\d{0,1})?$/;
    if (regex.test(base)) {
      setTotalhours(base);
      setDurError(false);
    } else {
      setDurError(true);
    }
  };
  const handleTotallectures = (base) => {
    const regex = /^\d{0,3}(\.\d{0,1})?$/;
    if (regex.test(base)) {
      setTotallectures(base);
      setLecError(false);
    } else {
      setLecError(true);
    }
  };
  const handleWebinarTime = (base) => {
    if (base < 13 && base > 0) {
      setWebinarTime(base);
      setTimeError(false);
    } else {
      setTimeError(true);
    }
  };
  const handleWebinarTimeMins = (base) => {
    if (base < 60 && base >= 0) {
      setWebinarTimeMins(base);
      setTimeMinsError(false);
    } else {
      setTimeMinsError(true);
    }
  };
  return (
    <>
      <Flex mt={10}>
        <Card w="90%" margin="0 auto">
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Add Course
            </Text>
            <Flex gap={30}>
              {type === "webinar" ? (
                <Button colorScheme="blue" onClick={WebinarDraft}>
                  Add Webinar
                </Button>
              ) : (
                <Button colorScheme="blue" onClick={CourseDraft}>
                  Add Course
                </Button>
              )}
              <Button colorScheme="blue" onClick={handleClick}>
                Cancel
              </Button>
            </Flex>
          </Flex>
          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>Landing Page</Tab>
              <Tab>Requirements & Objectives</Tab>
              <Tab>Curriculum</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {/* <LandingPage /> */}

                <Flex justifyContent="space-between" gap={5}>
                  <FormControl mt={5} isRequired isInvalid={istype}>
                    <Flex direction="column">
                      <FormLabel>Type</FormLabel>
                      <Select
                        placeholder="Select Type"
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                      >
                        <option value="live">Live</option>
                        <option value="recordedsession">
                          Recorded Session
                        </option>
                        <option value="webinar">Webinar</option>
                      </Select>
                    </Flex>
                  </FormControl>
                  <FormControl mt={5} isRequired isInvalid={isname}>
                    <Flex direction="column">
                      <FormLabel>Name</FormLabel>
                      <Input
                        type="Text"
                        placeholder="Upto 70 Characters"
                        maxLength="70"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Flex>
                  </FormControl>
                </Flex>

                <FormControl
                  mt={5}
                  isRequired
                  isInvalid={isShortdescriptionEmpty}
                >
                  <FormLabel>SubTitle</FormLabel>
                  <Input
                    type="Text"
                    placeholder="Upto 200 Characters"
                    maxLength="200"
                    onChange={(e) => {
                      setShortdescription(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl mt={5} isRequired isInvalid={isdesc}>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    placeholder="Upto 1000 Characters"
                    maxLength="1000"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </FormControl>
                {type === "webinar" ? (
                  <>
                    <Flex justifyContent="space-between" gap={5}>
                      {/* <FormControl mt={5} isRequired isInvalid={isday}>
                        <Flex direction="column">
                          <FormLabel>Day</FormLabel>
                          <Select
                            placeholder="Select Day"
                            onChange={(e) => {
                              setWebDay(e.target.value);
                            }}
                          >
                            <option value="Sunday">Sunday</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>\
                          </Select>
                        </Flex>
                      </FormControl> */}
                    </Flex>
                    <Flex justifyContent="space-between" gap={5}>
                      <FormControl mt={5} isRequired isInvalid={isWebd}>
                        <Flex direction="column">
                          <FormLabel>Date Webinar</FormLabel>
                          <Input
                            type="Date"
                            min={date}
                            placeholder="Write Day and Month"
                            maxLength="200"
                            onChange={(e) => {
                              setWebinarDate(e.target.value);
                            }}
                          />
                        </Flex>
                      </FormControl>
                      <FormControl mt={5} isRequired isInvalid={isWebt}>
                        <Flex direction="column">
                          <FormLabel>Hours</FormLabel>
                          <Input
                            type="number"
                            max={12}
                            min={1}
                            placeholder="Write Time"
                            onChange={(e) => {
                              handleWebinarTime(e.target.value);
                            }}
                          />
                        </Flex>
                        {timeError && (
                          <FormHelperText color={"red"}>
                            Enter number should not exceed 12 or preceed 0
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl mt={5} isRequired isInvalid={isWebtm}>
                        <Flex direction="column">
                          <FormLabel>Minutes</FormLabel>
                          <Input
                            type="number"
                            max={59}
                            min={1}
                            placeholder="Write minutes"
                            onChange={(e) => {
                              handleWebinarTimeMins(e.target.value);
                            }}
                          />
                        </Flex>
                        {timeMinsError && (
                          <FormHelperText color={"red"}>
                            Enter number should not exceed 59 or preceed 1
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl mt={5} isRequired isInvalid={istime}>
                        <Flex direction="column">
                          <FormLabel>Am/Pm</FormLabel>
                          <Select
                            onChange={(e) => {
                              setTimeZone(e.target.value);
                            }}
                          >
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </Select>
                        </Flex>
                      </FormControl>
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
                <Flex justifyContent="space-between" gap={5}>
                  <FormControl mt={5} isRequired isInvalid={islang}>
                    <Flex direction="column">
                      <FormLabel>Language</FormLabel>
                      <Select
                        placeholder="Select Type"
                        onChange={(e) => {
                          setLanguage(e.target.value);
                        }}
                      >
                        <option value="Hindi">Hindi</option>
                        <option value="English">English</option>
                      </Select>
                    </Flex>
                  </FormControl>
                  <FormControl mt={5} isRequired isInvalid={istag}>
                    <Flex direction="column">
                      <FormLabel>Tags</FormLabel>
                      <TagsInput selectedTags={selectedTags} />
                    </Flex>
                  </FormControl>
                </Flex>

                <Flex justifyContent="space-between" gap={5}>
                  <FormControl mt={5} isRequired isInvalid={isicon}>
                    <Flex direction="column">
                      <FormLabel>Image Upload</FormLabel>
                      <Input
                        type="file"
                        accept="images/*"
                        onChange={(e) =>
                          handleFileInputChangeImage(e.target.files[0])
                        }
                      />
                    </Flex>
                    <FormHelperText>
                      (Recommended Image Resolution 350p X 400p and Size is
                      10mb)
                    </FormHelperText>
                    {picLoading && <div>Uploading....</div>}
                  </FormControl>
                  <FormControl mt={5} isRequired isInvalid={ismed}>
                    <Flex direction="column">
                      <FormLabel>Video Upload</FormLabel>
                      <Input
                        type="file"
                        onChange={(e) =>
                          handleFileInputChangeVideo(e.target.files[0])
                        }
                        accept="video/*"
                      />
                    </Flex>
                    <FormHelperText>(Recommended Size is 500mb)</FormHelperText>
                    {vidLoading && <div>Uploading....</div>}
                  </FormControl>
                </Flex>

                <Flex justifyContent="space-between" gap={5}>
                  <FormControl mt={10} isRequired isInvalid={isbp}>
                    <Flex direction="column">
                      <FormLabel>Original Price</FormLabel>
                      <Input
                        type="number"
                        placeholder="Original Price"
                        onChange={(e) => handleBasePrice(e.target.value)}
                      />
                      {baseError && (
                        <FormHelperText color={"red"}>
                          Enter number should not exceed 9999999 or more than
                          one decimal number
                        </FormHelperText>
                      )}
                    </Flex>
                  </FormControl>
                  <FormControl mt={10} isRequired isInvalid={istp}>
                    <Flex direction="column">
                      <FormLabel>Discounted Price</FormLabel>
                      <Input
                        type="number"
                        placeholder="Dsicounted Price"
                        onChange={(e) => handleTotalPrice(e.target.value)}
                      />
                      {totalError && (
                        <FormHelperText color={"red"}>
                          Enter number should not exceed 9999999 or more than
                          one decimal number
                        </FormHelperText>
                      )}
                    </Flex>
                  </FormControl>
                </Flex>

                <Flex justifyContent="space-between" gap={5}>
                  <FormControl mt={10} isRequired isInvalid={isth}>
                    <Flex direction="column">
                      <FormLabel>Total Hours</FormLabel>
                      <Input
                        type="number"
                        placeholder="Enter Number of Hours"
                        onChange={(e) => handleTotalhours(e.target.value)}
                      />
                      {durError && (
                        <FormHelperText color={"red"}>
                          Enter number should not exceed 999 or more than one
                          decimal number
                        </FormHelperText>
                      )}
                    </Flex>
                  </FormControl>
                  <FormControl mt={10} isRequired isInvalid={istl}>
                    <Flex direction="column">
                      <FormLabel>Total Lectures</FormLabel>
                      <Input
                        type="number"
                        placeholder="Enter total number of lectures"
                        onChange={(e) => handleTotallectures(e.target.value)}
                      />
                      {lecError && (
                        <FormHelperText color={"red"}>
                          Enter number should not exceed 999 or more than one
                          decimal number
                        </FormHelperText>
                      )}
                    </Flex>
                  </FormControl>
                </Flex>
                {type === "live" ? (
                  <>
                    <Flex mt={10}>
                      <Text fontSize="xl">Weekdays</Text>
                    </Flex>
                    <Flex justifyContent="space-between" gap={5}>
                      <FormControl mt={4} isRequired isInvalid={isBatch1}>
                        <Flex direction="column">
                          <FormLabel>Batch 1</FormLabel>
                          <Input
                            type="date"
                            min={date}
                            onKeyDown={handleKeyDown}
                            placeholder="Enter Number of Hours"
                            onChange={(e) => setBatch1(e.target.value)}
                          />
                        </Flex>
                      </FormControl>
                      <FormControl mt={4} isRequired isInvalid={isBatch2}>
                        <Flex direction="column">
                          <FormLabel>Batch 2</FormLabel>
                          <Input
                            type="date"
                            min={date}
                            onKeyDown={handleKeyDown}
                            placeholder="Enter total number of lectures"
                            onChange={(e) => setBatch2(e.target.value)}
                          />
                        </Flex>
                      </FormControl>
                    </Flex>
                    <Flex mt={10}>
                      <Text fontSize="xl">Weekends</Text>
                    </Flex>
                    <Flex justifyContent="space-between" gap={5}>
                      <FormControl mt={4} isRequired isInvalid={isBatch3}>
                        <Flex direction="column">
                          <FormLabel>Batch 1</FormLabel>
                          <Input
                            type="date"
                            min={date}
                            onKeyDown={handleKeyDown}
                            placeholder="Enter Number of Hours"
                            onChange={(e) => setBatch3(e.target.value)}
                          />
                        </Flex>
                      </FormControl>
                      <FormControl mt={4} isRequired isInvalid={isBatch4}>
                        <Flex direction="column">
                          <FormLabel>Batch 2</FormLabel>
                          <Input
                            type="date"
                            min={date}
                            onKeyDown={handleKeyDown}
                            placeholder="Enter total number of lectures"
                            onChange={(e) => setBatch4(e.target.value)}
                          />
                        </Flex>
                      </FormControl>
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
              </TabPanel>
              <TabPanel>
                {/* <Benefits /> */}
                <Box>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="xl" fontWeight="500">
                      Objectives of Course
                    </Text>
                    <AddIcon onClick={handleClickObj} />
                  </Flex>
                  <FormControl mt={5} isRequired isInvalid={isobj}>
                    {objectives.map((objective, index) => (
                      <Flex key={index} alignItems="center" mt="2">
                        <Input
                          type="text"
                          value={objective}
                          maxLength="200"
                          onChange={(event) => handleChangeObj(index, event)}
                          w="80%"
                        />
                        <DeleteIcon
                          onClick={() => handleDeleteObj(index)}
                          ml="4"
                        />
                      </Flex>
                    ))}
                  </FormControl>
                </Box>
                <Box mt="5">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="xl" fontWeight="500">
                      Requirements
                    </Text>
                    <AddIcon onClick={handleClickReq} />
                  </Flex>
                  <FormControl mt={5} isRequired isInvalid={isreq}>
                    {requirements.map((requirement, index) => (
                      <Flex key={index} alignItems="center" mt="2">
                        <Input
                          type="text"
                          value={requirement}
                          maxLength="200"
                          onChange={(event) => handleChangeReq(index, event)}
                          w="80%"
                        />
                        <DeleteIcon
                          onClick={() => handleDeleteReq(index)}
                          ml="4"
                        />
                      </Flex>
                    ))}
                  </FormControl>
                </Box>
              </TabPanel>
              <TabPanel>
                {/* <Curriculum /> */}
                <>
                  <Box>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text fontSize="2xl" fontWeight="500">
                        Curriculum
                      </Text>
                      <Box onClick={onOpenModal1}>
                        <Button colorScheme="blue">Add Section</Button>
                      </Box>
                      <Modal
                        initialFocusRef={initialRef1}
                        finalFocusRef={finalRef1}
                        isOpen={isOpenModal1}
                        onClose={onCloseModal1}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Add Section</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody pb={6}>
                            {" "}
                            <FormControl mt="5">
                              <FormLabel>Name</FormLabel>
                              <Input
                                type="text"
                                required={true}
                                placeholder="Enter Name"
                                onChange={(e) => setSectionname(e.target.value)}
                              />
                            </FormControl>
                          </ModalBody>

                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={handleSection}
                            >
                              Add
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Flex>
                  </Box>

                  {section.map((div, index) => (
                    <Box
                      mt="5"
                      border="1px solid lightgrey"
                      p="4"
                      borderRadius="10"
                      key={index}
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <Flex alignItems="center">
                          <Text fontSize="xl" fontWeight="500">
                            {div.topicname}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" gap={"2rem"}>
                          <Box
                            onClick={() => {
                              setSelectedSectionId(div.id);
                              onOpenModal3();
                            }}
                          >
                            <AddIcon />
                          </Box>
                          <Modal
                            initialFocusRef={initialRef3}
                            finalFocusRef={finalRef3}
                            isOpen={isOpenModal3}
                            onClose={onCloseModal3}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>Add Lecture</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody pb={6}>
                                <FormControl mt="5">
                                  <FormLabel>Name</FormLabel>
                                  <Input
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={(e) =>
                                      setLecturename(e.target.value)
                                    }
                                  />
                                </FormControl>
                                <FormControl mt="5">
                                  <FormLabel>Upload Video</FormLabel>
                                  <Flex gap={2} alignItems="center">
                                    <Input
                                      type="file"
                                      placeholder="Upload video"
                                      accept="video/*"
                                      onChange={handleFileInput}
                                    />
                                    <Button
                                      onClick={(e) =>
                                        handleUpload(selectedFile)
                                      }
                                      variant="brand"
                                    >
                                      Upload
                                    </Button>
                                  </Flex>
                                  <FormHelperText>
                                    (Size should be under 1gb)
                                  </FormHelperText>
                                  {lecLoading && (
                                    <Progress
                                      value={progress}
                                      w="100%"
                                      mt="4"
                                    ></Progress>
                                  )}
                                </FormControl>
                                <FormControl mt="5">
                                  <FormLabel>Private</FormLabel>
                                  <Select
                                    placeholder="Select Type"
                                    onChange={(e) =>
                                      setVidprivate(e.target.value)
                                    }
                                  >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                  </Select>
                                </FormControl>
                                <Button
                                  colorScheme="blue"
                                  mt={7}
                                  mr={3}
                                  onClick={() =>
                                    handleLecture(selectedSectionId)
                                  }
                                  isLoading={lecLoading}
                                >
                                  Add
                                </Button>
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                          <Box
                            onClick={() => {
                              setSelectedSectionId(div.id);
                              onOpenModal2();
                            }}
                          >
                            <EditIcon />
                          </Box>
                          <Modal
                            initialFocusRef={initialRef2}
                            finalFocusRef={finalRef2}
                            isOpen={isOpenModal2}
                            onClose={onCloseModal2}
                          >
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>Edit Section</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody pb={6}>
                                <FormControl mt="5">
                                  <FormLabel>Name</FormLabel>
                                  <Input
                                    type="text"
                                    required={true}
                                    placeholder="Enter Name"
                                    onChange={(e) =>
                                      setNewSectionName(e.target.value)
                                    }
                                  />
                                </FormControl>
                              </ModalBody>

                              <ModalFooter>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={(e) => {
                                    handleEditSection(
                                      selectedSectionId,
                                      newSectionName
                                    );
                                    console.log(div.id);
                                  }}
                                >
                                  Edit
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                          <DeleteIcon
                            onClick={() => {
                              console.log(div.id);
                              handleSectionDelete(div.id);
                            }}
                          />
                        </Flex>
                      </Flex>
                      {div.subtopic.map((lecture, index) => (
                        <>
                          <Flex
                            mt="4"
                            direction="column"
                            justifyContent="space-between"
                            border="1px solid lightgray"
                            borderRadius="10"
                            p="2"
                            key={index + 1}
                          >
                            <Flex
                              alignContent="center"
                              justifyContent="space-between"
                            >
                              <Text>
                                Private:
                                {lecture.private}
                              </Text>
                              <Flex alignItems="center" gap={"2rem"}>
                                <Box
                                  onClick={() => {
                                    setSelectedSectionId(div.id);
                                    setSelectedLectureId(lecture.id);
                                    onOpenModal4();
                                  }}
                                >
                                  <EditIcon />
                                </Box>
                                <Modal
                                  initialFocusRef={initialRef4}
                                  finalFocusRef={finalRef4}
                                  isOpen={isOpenModal4}
                                  onClose={onCloseModal4}
                                >
                                  <ModalOverlay />
                                  <ModalContent>
                                    <ModalHeader>Edit Lecture</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody pb={6}>
                                      <FormControl mt="5">
                                        <FormLabel>Name</FormLabel>
                                        <Input
                                          type="text"
                                          placeholder="Enter Name"
                                          onChange={(e) =>
                                            setNewName(e.target.value)
                                          }
                                        />
                                      </FormControl>
                                      <FormControl mt="5">
                                        <FormLabel>Upload Video</FormLabel>
                                        <Flex gap={2} alignItems="center">
                                          <Input
                                            type="file"
                                            placeholder="Upload video"
                                            accept="video/*"
                                            onChange={handleFileInput}
                                          />
                                          <Button
                                            onClick={(e) =>
                                              handleUpload(selectedFile)
                                            }
                                            variant="brand"
                                          >
                                            Upload
                                          </Button>
                                        </Flex>
                                        <FormHelperText>
                                          (Size should be under 1gb)
                                        </FormHelperText>
                                        {lecLoading && (
                                          <Progress
                                            value={progress}
                                            w="100%"
                                            mt="4"
                                          ></Progress>
                                        )}
                                      </FormControl>
                                      <FormControl mt="5">
                                        <FormLabel>Private</FormLabel>
                                        <Select
                                          placeholder="Select Type"
                                          onChange={(e) =>
                                            setNewPrivate(e.target.value)
                                          }
                                          defaultValue={lecture.private}
                                        >
                                          <option value="true">True</option>
                                          <option value="false">False</option>
                                        </Select>
                                      </FormControl>
                                      <Button
                                        colorScheme="blue"
                                        mt={7}
                                        mr={3}
                                        onClick={() =>
                                          handleEditLecture(
                                            selectedSectionId,
                                            selectedLectureId,
                                            newName,
                                            newVideourl,
                                            newPrivate,
                                            newDuration
                                          )
                                        }
                                        isLoading={lecLoading}
                                      >
                                        Save
                                      </Button>
                                    </ModalBody>

                                    <ModalFooter></ModalFooter>
                                  </ModalContent>
                                </Modal>
                                <DeleteIcon
                                  onClick={() =>
                                    handleDeleteLecture(div.id, lecture.id)
                                  }
                                />
                              </Flex>
                            </Flex>

                            <Text>{lecture.name}</Text>
                            <Text>{lecture.videourl}</Text>
                          </Flex>
                        </>
                      ))}
                    </Box>
                  ))}
                </>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Card>
      </Flex>
    </>
  );
}
