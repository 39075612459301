import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  Select,
  useToast,
} from "@chakra-ui/react";
import UserApi from "api/user";
import { useAuth } from "../../../../auth-context/auth.context";
export default function PopModal(props) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneNumberRegex = /^\d{10}$/;
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const toast = useToast();
  const [token, setToken] = useState(user.token);
  const [response, setResponse] = useState();
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState(false);
  const addUser = async (e) => {
    e.preventDefault();
    if (!email || !name || !phoneNumber || !role) {
      toast({
        title: "All fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        const api = await UserApi.UserCreate({
          name,
          email,
          phoneNumber,
          role,
          token,
        });
        setResponse(api.data);
        console.log(api.data, "api");
        if (api.data.statusCode === 1) {
          toast({
            title: "Successfully Added",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          onClose();
          props.func();
        } else if (api.data.statusCode === 0) {
          let error = response.error.msg;
          toast({
            title: error,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }

        // console.log(response);q
        // setTimeout(() => {
        //   window.location.reload();
        // }, "2000");
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <>
      <Button onClick={onOpen}>{props.Icon}</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.head}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="First name"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </FormControl>

            <FormControl mt={4} isInvalid={emailError}>
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Email"
                onChange={(e) => {
                  const input = e.target.value;
                  setEmail(input);
                  if (!emailRegex.test(input)) {
                    setEmailError(true);
                  } else {
                    setEmailError(false);
                  }
                }}
                required
              />
            </FormControl>
            <FormControl mt={4} isInvalid={error}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                placeholder="number"
                onChange={(e) => {
                  const input = e.target.value;
                  setPhoneNumber(input);

                  if (!phoneNumberRegex.test(input)) {
                    setError(true);
                  } else {
                    setError(false);
                  }
                }}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              <Select
                placeholder="Select option"
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="admin">Admin</option>
                <option value="superadmin">SuperAdmin</option>
                <option value="instructor">Instructor</option>
                <option value="student">Student</option>
                <option value="seoExpert">SEO Expert</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={addUser}
              disabled={error || emailError ? true : false}
            >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
