import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { EditIcon, CheckIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import ContentApi from "api/content";
import { useAuth } from "../../../../auth-context/auth.context";
export default function Challenge() {
  const toast = useToast();
  const { user } = useAuth();
  const [disabled, setDisabled] = useState(true);
  const [list, setList] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [id, setId] = useState();
  const [token, setToken] = useState(user.token);
  useEffect(async () => {
    try {
      let response = await ContentApi.ContentChallenge();
      response = response.data;
      let responseData = response.responseData;
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setId(responseData._id);
        const data = responseData.contentAdmin.list;
        const inputValuesArray = data.map((value) => value);
        setList(data);
        setInputValues(inputValuesArray);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleInputChange = (index, value) => {
    setInputValues((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  const handlePublish = async () => {
    try {
      let response = await ContentApi.publishHome({
        id,
        token,
      });
      response = response.data;
      console.log(response);
      if (response.statusCode == 1) {
        toast({
          title: "Successfully Published",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      } else if (response.statusCode !== 1) {
        toast({
          title: "Error Publishing",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
    setDisabled(true);
  };

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mt={10}>
        <Text fontSize="2xl" fontWeight="500">
          30 Day Challenge Page
        </Text>
        {disabled ? (
          <EditIcon
            ml="5"
            fontSize="large"
            onClick={() => setDisabled(false)}
            cursor="pointer"
          />
        ) : (
          <CheckIcon
            ml="5"
            fontSize="large"
            onClick={() => setDisabled(true)}
            cursor="pointer"
          />
        )}
      </Flex>
      <Flex direction="column">
        {list.length > 0 ? (
          <>
            <FormControl mt={5}>
              <Flex alignItems="center" justifyContent="space-between">
                <FormLabel>Variables</FormLabel>
              </Flex>
              {inputValues.map((value, index) => (
                <Flex alignItems="center" mt="2" key={index}>
                  <Textarea
                    type="text"
                    w="100%"
                    maxLength="200"
                    disabled={disabled}
                    defaultValue={value}
                    onChange={(event) =>
                      handleInputChange(index, event.target.value)
                    }
                  />
                </Flex>
              ))}
            </FormControl>
            <FormControl mt={5}>
              <Button
                colorScheme="blue"
                disabled={disabled}
                onClick={handlePublish}
              >
                Publish
              </Button>
            </FormControl>
          </>
        ) : (
          <div>Error in fetching</div>
        )}
      </Flex>
    </>
  );
}
