import React, { useRef, useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  Button,
  Icon,
  Select,
  Stack,
  Box,
  useToast,
} from "@chakra-ui/react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { format, set } from "date-fns";
import Card from "components/card/Card";
import CourseApi from "../../../../api/course";
import TotalSpent from "./TotalSpent";
import TotalConversion from "./TotalConversion";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
import { useAuth } from "../../../../auth-context/auth.context";
import { useHistory } from "react-router-dom";

export default function Performance() {
  const { user } = useAuth();
  const editor = useRef(null);
  const toast = useToast();
  const [showPicker, setShowPicker] = useState(false);
  const [content, setContent] = useState("");
  const [students, setStudents] = useState("");
  const [data, setData] = useState();
  const [list, setList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [chartPrice, setChartPrice] = useState([]);
  const [chartDate, setChartDate] = useState([]);
  const [price, setPrice] = useState("");
  const [chartStudent, setChartStudent] = useState([]);
  const [visit, setVisit] = useState("");
  const [chartVisit, setChartVisit] = useState([]);
  const [chartType, setChartType] = useState("");
  const history = useHistory();

  let rowNumber = 0;
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const fetchList = async () => {
    try {
      let response = await CourseApi.CourseList();
      response = response.data;

      let responseData = response.responseData;

      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setList(responseData.reverse());
        // console.log(list);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(async () => {
    fetchList();
  }, []);
  useEffect(() => {
    const selectedOption = list?.find((course) => course._id === courseId);
    if (selectedOption) {
      setCourseName(selectedOption.name);
    } else {
      setCourseName("");
    }
  }, [courseId, list]);
  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    // console.log(selectionRange);
  };

  const handleClick = async () => {
    if (
      !selectionRange.startDate ||
      !selectionRange.endDate ||
      !courseId ||
      !chartType
    ) {
      toast({
        title: "Please Select Course and Date Range",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      if (chartType === "D") {
        var s = format(selectionRange.startDate, "yyyy/MM/dd");
        var e = format(selectionRange.endDate, "yyyy/MM/dd");
        for (
          var a = [], d = new Date(s);
          d <= new Date(e);
          d.setDate(d.getDate() + 1)
        ) {
          a.push(new Date(d).toISOString().substring(0, 10));
        }
        setChartDate(a);
      } else if (chartType === "M") {
        var s = format(selectionRange.startDate, "yyyy/MM/dd");
        var e = format(selectionRange.endDate, "yyyy/MM/dd");
        for (
          var m = [], d = new Date(s);
          d <= new Date(e);
          d.setMonth(d.getMonth() + 1)
        ) {
          m.push(new Date(d).toISOString().substring(0, 7));
        }
        setChartDate(m);
      } else if (chartType === "Y") {
        var s = format(selectionRange.startDate, "yyyy/MM/dd");
        var e = format(selectionRange.endDate, "yyyy/MM/dd");
        for (
          var y = [], d = new Date(s);
          d <= new Date(e);
          d.setYear(d.getFullYear() + 1)
        ) {
          y.push({
            data: new Date(d).toISOString().substring(0, 4),
            studentCount: 0,
            price: 0,
          });
        }
        setChartDate(y);
      }
      // console.log("date", chartDate);
      let res = await CourseApi.CourseStudentList({
        group: chartType,
        startDate: format(selectionRange.startDate, "yyyy/MM/dd"),
        endDate: format(selectionRange.endDate, "yyyy/MM/dd"),
        courseId: courseId === "all" ? "" : courseId,
        token: user.token,
      });
      let filter;

      if (courseId === "all") {
        filter = {};
      } else {
        filter = {
          activityType: ["PageView"],
          activityPage: [courseName],
          uniqueId: [courseId],
        };
      }
      let response = await CourseApi.CourseTrafficList({
        group: chartType,
        startDate: format(selectionRange.startDate, "yyyy/MM/dd"),
        endDate: format(selectionRange.endDate, "yyyy/MM/dd"),
        filter: filter,
        token: user.token,
      });
      const responseData = response?.data?.responseData;
      console.log(response.data, "res");
      if (responseData) {
        const totalVisit = Object.values(responseData).reduce(
          (count, arr) => count + (Array.isArray(arr) ? arr.length : 0),
          0
        );

        const newVisitCount = new Array(chartDate.length).fill(0);

        for (const date of Object.keys(responseData)) {
          const dateIndex = chartDate.indexOf(date);

          if (dateIndex !== -1) {
            newVisitCount[dateIndex] = Array.isArray(responseData[date])
              ? responseData[date].length
              : 0;
          }
        }

        console.log(newVisitCount, "visit num");
        setVisit(totalVisit);
        setChartVisit(newVisitCount);
      } else {
        // Handle the case where responseData is undefined
        console.error("No responseData found in the API response.");
      }
      setData(res.data.responseData);
      const totalCount = Object.values(res.data.responseData).reduce(
        (count, arr) => count + arr.length,
        0
      );
      const totalArray = [].concat(...Object.values(res.data.responseData));
      // console.log(totalArray);
      setCurrentItems(totalArray);
      setStudents(totalCount);
      setShowPicker(false);
      // console.log(totalCount);
    }
  };
  const setChartData = () => {
    const newPriceCount = new Array(chartDate.length).fill(0);
    const newStudentCount = new Array(chartDate.length).fill(0);
    const studentCount = Object.values(data).map((arr) => arr.length);

    const priceCount = Object.values(data).map((arr) =>
      arr.reduce((total, i) => total + i.orderId.totalPrice, 0)
    );

    const totalPrice = Object.values(data).reduce(
      (count, arr) =>
        count + arr.reduce((sum, i) => sum + i.orderId.totalPrice, 0),
      0
    );
    for (const date of Object.keys(data)) {
      const dateIndex = chartDate.indexOf(date);

      if (dateIndex !== -1) {
        // Update the new arrays with real values
        newPriceCount[dateIndex] = data[date].reduce(
          (total, i) => total + i.orderId.totalPrice,
          0
        );
        newStudentCount[dateIndex] = data[date].length;
      }
    }
    setChartPrice(newPriceCount);
    setPrice(totalPrice);
    setChartStudent(newStudentCount);
    // console.log(chartStudent, "sty");
    // console.log(chartPrice, "stp");
    // console.log(newPriceCount, "npc");
    // console.log(newStudentCount, "nsc");
  };
  useEffect(() => {
    if (data) {
      setChartData();
    }
  }, [data]);
  useEffect(() => {
    if (students) {
      setTotalPages(Math.ceil(students / itemsPerPage));
    }
  }, [students, itemsPerPage]);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Button
        key={i}
        size="sm"
        variant="ghost"
        colorScheme={i === currentPage ? "blue" : undefined}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Button>
    );
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItemss = currentItems?.slice(startIndex, endIndex);
  // console.log(currentItemss, "curr");
  return (
    <>
      <Flex mt={10}>
        <Card mt={20} w="90%" margin="0 auto">
          <Flex pt="25px" justify="space-between" mb="20px" align="center">
            <Text fontSize="22px" fontWeight="700" lineHeight="100%">
              Performance
            </Text>
            <Button
              variant="brand"
              onClick={() => {
                history.push("admin/courses");
              }}
            >
              Cancel
            </Button>
          </Flex>
          <Flex m="5" justifyContent="space-between" alignItems="center">
            <Select
              placeholder="Select Course"
              w="40%"
              onChange={(e) => {
                setCourseId(e.target.value);
              }}
            >
              <option value="all">Select all</option>
              {list?.map((course) => {
                return (
                  <option key={course._id} value={course._id}>
                    {course.name}
                  </option>
                );
              })}
            </Select>
            <Select
              placeholder="Select Chart Type"
              w="20%"
              onChange={(e) => {
                setChartType(e.target.value);
              }}
            >
              <option value="D">Day</option>
              <option value="M">Month</option>
              <option value="Y">Year</option>
            </Select>
            <Button
              fontSize="sm"
              fontWeight="500"
              borderRadius="7px"
              onClick={() => setShowPicker(!showPicker)}
              variant="brand"
              w="20%"
            >
              <Icon as={MdOutlineCalendarToday} me="4px" />
              {!showPicker ? "Select Date" : "Close Date"}
            </Button>
            <Button
              fontSize="sm"
              fontWeight="500"
              borderRadius="7px"
              variant="brand"
              w="15%"
              onClick={handleClick}
            >
              See List
            </Button>
          </Flex>
          {showPicker && (
            <Flex left={3} justify={"center"} width="100%" mt={4}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                verticalPlacement="top"
              />
            </Flex>
          )}
          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>Overview</Tab>
              <Tab>Students</Tab>
              <Tab>Traffic & Conversion</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <TotalSpent
                  list={list}
                  chartprice={chartPrice}
                  chartstudent={chartStudent}
                  price={price}
                  students={students}
                  data={data}
                  chartdate={chartDate}
                />
              </TabPanel>
              <TabPanel>
                {students ? (
                  <>
                    <Text mt={5} fontSize={"xl"}>
                      Students: {students}
                    </Text>
                    <TableContainer mt="3">
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th>User Id</Th>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Mobile</Th>
                            <Th>Course</Th>
                            <Th>Enrollment Date</Th>
                            <Th>Location</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentItemss.map((item, index) => (
                            <Tr key={item._id}>
                              <Td>{startIndex + index + 1}</Td>
                              <Td>{item?.userId?.name}</Td>
                              <Td>{item?.userId?.email}</Td>
                              <Td>{item?.userId?.phoneNumber}</Td>
                              <Td>{item?.courseId?.name}</Td>
                              <Td>{item?.startDate.slice(0, 10)}</Td>

                              <Td>
                                {item?.userId?.city
                                  ? item?.userId?.city
                                  : "Not Known"}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      <Stack
                        direction="row"
                        spacing={2}
                        mt={4}
                        justifyContent="center"
                      >
                        <Box>
                          <Button
                            size="sm"
                            isDisabled={currentPage === 1}
                            onClick={() => onPageChange(currentPage - 1)}
                          >
                            Previous
                          </Button>
                        </Box>

                        {pageItems}

                        <Box>
                          <Button
                            size="sm"
                            isDisabled={currentPage === totalPages}
                            onClick={() => onPageChange(currentPage + 1)}
                          >
                            Next
                          </Button>
                        </Box>
                      </Stack>
                    </TableContainer>
                  </>
                ) : (
                  <Flex
                    height={"50vh"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    Please Enter Data To See Students List
                  </Flex>
                )}
              </TabPanel>
              <TabPanel>
                <TotalConversion
                  list={list}
                  chartstudent={chartStudent}
                  students={students}
                  data={data}
                  visit={visit}
                  chartvisit={chartVisit}
                  chartdate={chartDate}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Card>
      </Flex>
    </>
  );
}
