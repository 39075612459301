import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Select,
} from "@chakra-ui/react";
export default function PopModal(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <>
      <Button onClick={onOpen}>{props.Icon}</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.head}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Type</FormLabel>

              {props.couponType}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Coupon Code</FormLabel>

              {props.couponCode}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>

              {props.description}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Max Consume</FormLabel>

              {props.maxConsume}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Discount Value</FormLabel>

              {props.discountValue}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Expires On</FormLabel>

              {props.expiresOn}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            {/* {props.button} */}
            <Button
              colorScheme="blue"
              mr={3}
              value={props.id}
              onClick={() => {
                props.handleUpdate(onClose, props.id);
              }}
            >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
