import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import ContentApi from "api/content";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import { useAuth } from "../../../../auth-context/auth.context";
export default function Lfsm() {
  const toast = useToast();
  const { user } = useAuth();
  const [disabled, setDisabled] = useState(true);
  const [list, setList] = useState([]);
  const [url1, setUrl1] = useState();
  const [url2, setUrl2] = useState();
  const [url3, setUrl3] = useState();
  const [url4, setUrl4] = useState();
  const [url5, setUrl5] = useState();
  const [url6, setUrl6] = useState();
  const [url7, setUrl7] = useState();
  const [url8, setUrl8] = useState();
  const [url9, setUrl9] = useState();
  const [id, setId] = useState();
  const [token, setToken] = useState(user.token);
  useEffect(async () => {
    try {
      let response = await ContentApi.ContentLFSM();
      response = response.data;
      let responseData = response.responseData;
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setId(responseData._id);

        const data = responseData.contentAdmin.list;
        setList(data);
        setUrl1(data[0].url1);
        setUrl2(data[1].url2);
        setUrl3(data[2].url3);
        setUrl4(data[3].url4);
        setUrl5(data[4].url5);
        setUrl6(data[5].url6);
        setUrl7(data[6].url7);
        setUrl8(data[7].url8);
        setUrl9(data[8].url9);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);
  const handleUpdate = async () => {
    const inputValues = [
      { url1: url1 },
      { url2: url2 },
      { url3: url3 },
      { url4: url4 },
      { url5: url5 },
      { url6: url6 },
      { url7: url7 },
      { url8: url8 },
      { url9: url9 },
    ];
    try {
      let response = await ContentApi.UpdateLFSM({
        id,
        inputValues,
        token,
      });
      response = response.data;
      console.log(response);
      if (response.statusCode == 1) {
        toast({
          title: "Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      } else if (response.statusCode !== 1) {
        toast({
          title: "Error updating",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
    setDisabled(true);
  };
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mt={10}>
        <Text fontSize="2xl" fontWeight="500">
          Learn Free Stock Market
        </Text>
        {disabled ? (
          <EditIcon
            ml="5"
            fontSize="large"
            onClick={() => setDisabled(false)}
            cursor="pointer"
          />
        ) : (
          <CheckIcon
            ml="5"
            fontSize="large"
            onClick={() => setDisabled(true)}
            cursor="pointer"
          />
        )}
      </Flex>
      <Flex direction="column">
        {list.length > 0 ? (
          <>
            <FormControl mt={5}>
              <Flex alignItems="center" justifyContent="space-between">
                <FormLabel>Links</FormLabel>
              </Flex>

              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url1}
                  onChange={(event) => setUrl1(event.target.value)}
                />
              </Flex>
              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url2}
                  onChange={(event) => setUrl2(event.target.value)}
                />
              </Flex>
              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url3}
                  onChange={(event) => setUrl3(event.target.value)}
                />
              </Flex>
              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url4}
                  onChange={(event) => setUrl4(event.target.value)}
                />
              </Flex>
              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url5}
                  onChange={(event) => setUrl5(event.target.value)}
                />
              </Flex>
              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url6}
                  onChange={(event) => setUrl6(event.target.value)}
                />
              </Flex>
              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url7}
                  onChange={(event) => setUrl7(event.target.value)}
                />
              </Flex>
              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url8}
                  onChange={(event) => setUrl8(event.target.value)}
                />
              </Flex>
              <Flex alignItems="center" mt="2">
                <Input
                  type="text"
                  w="100%"
                  maxLength="200"
                  disabled={disabled}
                  defaultValue={url9}
                  onChange={(event) => setUrl9(event.target.value)}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <Button
                colorScheme="blue"
                disabled={disabled}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </FormControl>
          </>
        ) : (
          <div>Error in fetching</div>
        )}
      </Flex>
    </>
  );
}
