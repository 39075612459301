import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdLogout,
  MdOutlineShoppingCart,
} from "react-icons/md";
import Contact from "views/admin/contact";
// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
// import DataTables from "views/admin/dataTables";
// import RTL from "views/admin/rtl";
import Users from "views/admin/users";
import Blogs from "views/admin/blogs";
import Courses from "views/admin/courses";
import Content from "views/admin/content";
import FAQ from  "views/admin/faq"
import Discount from 'views/admin/discount'
import BlogPublish from 'views/admin/blogPublish'
import contentPublish from 'views/admin/contentPublish'
import coursePublish from 'views/admin/coursePublish'
// Auth Imports
// import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn/index.jsx";
  
const routes = [

  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
    roles: ["superAdmin","admin","instructor","student","contentwriter"],
  },
 
  {
    name: "Users",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/users",
    component: Users,
    roles: ["superAdmin","admin",],
  },
  {
    name: "Blogs",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/blogs",
    component: Blogs,
    roles: ["superAdmin","admin","contentwriter","seoExpert"],
  },
    {
    name: "Blog Publish",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/bpublish",
    component: BlogPublish,
    roles: ["superAdmin"],
    
  },
  {
    name: "Course",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/courses",
    component: Courses,
    roles: ["superAdmin","admin","instructor"],
  },
    {
    name: "Course Publish",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/coursepublish",
    component: coursePublish,
    roles: ["superAdmin"],
  },
  {
    name: "Content",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/content",
    component: Content,
    roles: ["superAdmin","admin","contentwriter","seoExpert"],
  },
    {
    name: "Content Publish",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/conpublish",
    component: contentPublish,
    roles: ["superAdmin"],
  },
  {
    name: "Form Data",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/contact",
    component: Contact,
    roles: ["superAdmin","admin","instructor"],
  },
  {
    name: "FAQ",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/faq",
    component: FAQ,
    roles: ["superAdmin","admin","instructor"],
  },
   {
    name: "Discount",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/discount",
    component: Discount,
    roles: ["superAdmin","admin","instructor"],
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon as={MdHome} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
    roles: ["superadmin"],
    hide: true
  },
];

// export const Logout = [
//   {
//     name: "Log Out",
//     layout: "/auth",
//     path: "/sign-out",
//     icon: <Icon as={MdLogout} width="16px" height="16px" color="inherit" />,
//     component: SignIn,
//   },
// ];

export default routes;
