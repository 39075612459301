import React, { useEffect, useState } from "react";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Select,
  useDisclosure,
  Textarea,
  useToast,
  Stack,
  Box,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import PopDelete from "./components/deletePopover";
import Card from "components/card/Card";
import FaqApi from "api/faq";
import AddModal from "./components/addModal";
import EditModal from "./components/editModal";
import { useAuth } from "../../../auth-context/auth.context";
export default function Index() {
  const { user } = useAuth();
  const toast = useToast();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [list, setList] = useState([]);
  const [tags, setTags] = useState();
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(user.token);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const fetchList = async () => {
    try {
      setLoading(true);
      let response = await FaqApi.FaqList();
      response = response.data;
      setLoading(false);
      let responseData = response.responseData;
      // console.log(responseData)
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setList(responseData.reverse());
        setTags(responseData.tags);
        setQuestion(responseData.question);
        setAnswer(responseData.answer);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchList();
  }, []);
  useEffect(() => {
    if (list) {
      setTotalPages(Math.ceil(list.length / itemsPerPage));
    }
  }, [list, itemsPerPage]);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Button
        key={i}
        size="sm"
        variant="ghost"
        colorScheme={i === currentPage ? "blue" : undefined}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Button>
    );
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = list.slice(startIndex, endIndex);
  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const id = [e.target.value];
      const response = await FaqApi.FaqDelete({ id, token });
      // console.log(id);
      console.log(response.data);
      if (response.data.statusCode === 1) {
        toast({
          title: "Successfully Deleted",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        fetchList();
      } else if (response.data.statusCode !== 1) {
        toast({
          title: "Error Deleting",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdate = async (onClose, id) => {
    try {
      const response = await FaqApi.FaqUpdate({
        id,
        tags,
        question,
        answer,
        token,
      });
      // console.log(tags);
      console.log(response.data);
      if (response.data.statusCode == 1) {
        toast({
          title: "Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        onClose();
        fetchList();
      } else if (response.data.statusCode !== 1) {
        toast({
          title: "Error Updating",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
      // window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="20px"
        mt="7rem"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            FAQ's
          </Text>
          <AddModal Icon={<AddIcon />} head={"Add FAQ"} func={fetchList} />
        </Flex>
        {loading && (
          <Text fontSize="xl" fontWeight="700" mt={4} p={4}>
            Loading....
          </Text>
        )}
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr>
              <Th pe="10px" borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Id
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Category
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor} w="30%">
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                  w="35%"
                >
                  Question
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor} w="30%">
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                  w="35%"
                >
                  Answer
                </Flex>
              </Th>

              <Th pe="10px" borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Actions
                </Flex>
              </Th>
            </Tr>
          </Thead>

          <Tbody w={"100%"}>
            {currentItems.map((list, index) => {
              return (
                <Tr key={list._id} w={"100%"}>
                  <Td>
                    <Flex align="center">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {startIndex + index + 1}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex align="center">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {list.tags}
                      </Text>
                    </Flex>
                  </Td>
                  <Td maxWidth="350px">
                    <Flex align="center">
                      <Text
                        me="10px"
                        color={textColor}
                        fontSize="sm"
                        fontWeight="700"
                        noOfLines={1}
                      >
                        {list.question}
                      </Text>
                    </Flex>
                  </Td>
                  <Td maxWidth="350px">
                    <Text
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                      noOfLines={1}
                    >
                      {list.answer}
                    </Text>
                  </Td>

                  <Td>
                    <Flex>
                      <EditModal
                        Icon={<EditIcon />}
                        head={"Edit FAQ"}
                        type={
                          <Select
                            placeholder="Select Type"
                            defaultValue={list.tags}
                            onChange={(e) => setTags(e.target.value)}
                          >
                            <option value="general">General</option>
                            <option value="webinar">Webinar</option>
                            <option value="online course">Online Course</option>
                            <option value="offline course">
                              Offline Course
                            </option>
                            <option value="challenge">30 Days Challenge</option>
                            <option value="payment">Payment</option>
                          </Select>
                        }
                        question={
                          <Flex alignItems="center">
                            <Textarea
                              type="text"
                              placeholder="Upto 200 Characters"
                              maxLength="200"
                              defaultValue={list.question}
                              onChange={(e) => setQuestion(e.target.value)}
                              required
                            />
                          </Flex>
                        }
                        answer={
                          <Flex alignItems="center">
                            <Textarea
                              type="text"
                              placeholder="Upto 400 Characters"
                              maxLength="400"
                              defaultValue={list.answer}
                              onChange={(e) => setAnswer(e.target.value)}
                              required
                            />
                          </Flex>
                        }
                        id={list._id}
                        handleUpdate={handleUpdate}
                      />

                      <PopDelete
                        Icon={<DeleteIcon />}
                        deleteButton={
                          <Button
                            colorScheme="red"
                            onClick={handleDelete}
                            value={list._id}
                          >
                            Delete
                          </Button>
                        }
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Stack direction="row" spacing={2} mt={4} justifyContent="center">
          <Box>
            <Button
              size="sm"
              isDisabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
            >
              Previous
            </Button>
          </Box>

          {pageItems}

          <Box>
            <Button
              size="sm"
              isDisabled={currentPage === totalPages}
              onClick={() => onPageChange(currentPage + 1)}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </Card>
    </>
  );
}
