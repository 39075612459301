import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Flex,
  Text,
  useToast,
  Textarea,
  border,
  FormHelperText,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import AuthApi from "../../../api/auth";
import { useAuth } from "../../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
export default function Index() {
  const history = useHistory();
  function handleCancel() {
    history.push("/");
  }
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneNumberRegex = /^\d{10}$/;
  const { user } = useAuth();
  const [token, setToken] = useState(user.token);
  const [id, setId] = useState(user._id);
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState(user.role);
  const [about, setAbout] = useState("about");
  const [pic, setPic] = useState();
  const [exp, setExp] = useState("");
  const [dob, setDob] = useState("");
  const [city, setCity] = useState("");
  const [picLoading, setPicLoading] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState();
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  const date = `${year}-${month}-${day}`;
  useEffect(async () => {
    try {
      let response = await AuthApi.UserData({
        token,
      });
      response = response.data;
      console.log(response);
      if (response.statusCode !== 1 && response.error) {
        return setError(response.error.errorMessage);
      } else {
        setResponse(response);
        setName(response.responseData.userId.name);
        setEmail(response.responseData.userId.email);
        setPhoneNumber(response.responseData.userId.phoneNumber);
        setCity(response.responseData.userId.city);
        setPic(response.responseData.pic);
        setExp(response.responseData.detailsJSON.exp);
        setDob(response.responseData.detailsJSON.dob);
        setAbout(response.responseData.about);
        setGender(response.responseData.gender);
      }
    } catch (err) {
      if (err.message) {
      }
    }
  }, []);
  const handleImage = (image) => {
    setPicLoading(true);
    if (image === undefined) {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (
      (image.type === "image/jpeg" || image.type === "image/png") &&
      image.size <= 10 * 1024 * 1024
    ) {
      const data = new FormData();
      data.append("file", image);
      fetch("https://api.stockventure.in/util/uploadmedia", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.responseData[0]);
          setPic(data.responseData[0]);
          setPicLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPicLoading(false);
        });
    } else {
      toast({
        title:
          "Please Select Proper Format and Size. (Only png and jpg are allowed and size limit is 10mb)",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setPicLoading(false);
      return;
    }
  };
  const handleClick = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !email ||
      !phoneNumber ||
      !about ||
      !gender ||
      !city ||
      !dob ||
      !exp
    ) {
      toast({
        title: "All fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        let response = await AuthApi.Profile({
          name,
          email,
          phoneNumber,
          about,
          pic,
          gender,
          city,
          dob,
          exp,
          token,
        });
        response = response.data;
        console.log(response);
        if (response.statusCode == 1) {
          toast({
            title: "Successfully Added",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else if (response.statusCode == 0) {
          let error = response.data.responseData.error.name;
          toast({
            title: error,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      } catch (err) {
        if (err.message) {
          // return setError(err.message);
        }
        // return setError("There has been an error.");
      }
    }
  };
  return (
    <Flex mt={10}>
      <Card w="90%" margin="0 auto">
        <Flex pt="25px" justify="space-between" mb="20px" align="center">
          <Text fontSize="22px" fontWeight="700" lineHeight="100%">
            Profile Page
          </Text>
        </Flex>
        {response !== undefined ? (
          <>
            <FormControl mt={5} isRequired>
              <Flex direction="column">
                <img
                  src={
                    response.responseData.pic
                      ? response.responseData.pic
                      : "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                  }
                  alt={"Image"}
                  style={{
                    height: "10rem",
                    width: "10rem",
                    borderRadius: "50%",
                    border: "1px solid lightgray",
                  }}
                />
              </Flex>
              <FormControl mt={5} isRequired>
                <Text fontSize="22px" fontWeight="700" lineHeight="100%">
                  Role: {role.toUpperCase()}
                </Text>
              </FormControl>
            </FormControl>
            <Flex justifyContent="space-between" gap={5}>
              <FormControl mt={5} isRequired>
                <Flex direction="column">
                  <FormLabel>Name</FormLabel>

                  <Input
                    type="Text"
                    defaultValue={response.responseData.userId.name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Flex>
              </FormControl>
              <FormControl mt={5} isRequired isInvalid={emailError}>
                <FormLabel>Email</FormLabel>
                <Flex direction="column">
                  <Input
                    type="email"
                    defaultValue={response.responseData.userId.email}
                    onChange={(e) => {
                      const input = e.target.value;
                      setEmail(input);
                      if (!emailRegex.test(input)) {
                        setEmailError(true);
                      } else {
                        setEmailError(false);
                      }
                    }}
                    required
                  />
                </Flex>
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between" gap={5}>
              <FormControl mt={5} isRequired isInvalid={error}>
                <Flex direction="column">
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    type="number"
                    defaultValue={response.responseData.userId.phoneNumber}
                    onChange={(e) => {
                      const input = e.target.value;
                      setPhoneNumber(input);

                      if (!phoneNumberRegex.test(input)) {
                        setError(true);
                      } else {
                        setError(false);
                      }
                    }}
                    required
                  />
                </Flex>
              </FormControl>
              <FormControl mt={5} isRequired>
                <FormLabel>Gender</FormLabel>
                <Flex direction="column">
                  <Select
                    placeholder="Select Type"
                    defaultValue={response.responseData.gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Select>
                </Flex>
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between" gap={5}>
              <FormControl mt={5} isRequired>
                <Flex direction="column">
                  <FormLabel>Date</FormLabel>

                  <Input
                    type="date"
                    max={date}
                    // defaultValue={response.responseData.detailsJSON.dob}
                    onKeyDown={handleKeyDown}
                    defaultValue={
                      response.responseData.detailsJSON
                        ? response.responseData.detailsJSON.dob
                          ? response.responseData.detailsJSON.dob !== null
                            ? response.responseData.detailsJSON.dob
                            : ""
                          : ""
                        : ""
                    }
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </Flex>
              </FormControl>
              <FormControl mt={5} isRequired>
                <FormLabel>City</FormLabel>
                <Flex direction="column">
                  <Input
                    type="text"
                    defaultValue={response.responseData.userId.city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </Flex>
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between" gap={5}>
              <FormControl mt={5} isRequired>
                <Flex direction="column">
                  <FormLabel>Profile</FormLabel>
                  <Input
                    type="file"
                    onChange={(e) => handleImage(e.target.files[0])}
                    required
                  />
                </Flex>
                <FormHelperText>
                  (Recommended Image Resolution 160p X 160p and Size is 10mb)
                </FormHelperText>
                {picLoading && <div>Uploading....</div>}
              </FormControl>
              <FormControl mt={5} isRequired>
                <FormLabel>Experience</FormLabel>
                <Flex direction="column">
                  {console.log(exp)}
                  <Input
                    type="number"
                    defaultValue={
                      response.responseData.detailsJSON
                        ? response.responseData.detailsJSON.exp
                          ? response.responseData.detailsJSON.exp !== null
                            ? response.responseData.detailsJSON.exp
                            : ""
                          : ""
                        : ""
                    }
                    onChange={(e) => setExp(e.target.value)}
                    required
                  />
                </Flex>
              </FormControl>
            </Flex>

            <FormControl mt={5} isRequired>
              <FormLabel>About</FormLabel>
              <Flex direction="column">
                <Textarea
                  defaultValue={response.responseData.about}
                  onChange={(e) => setAbout(e.target.value)}
                />
              </Flex>
            </FormControl>

            <FormControl mt={5}>
              <Flex gap={30} justifyContent="flex-end">
                <Button variant="brand" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  variant="brand"
                  onClick={handleClick}
                  w="10%"
                  disabled={error || emailError ? true : false}
                >
                  Save
                </Button>
              </Flex>
            </FormControl>
          </>
        ) : (
          <>Loading</>
        )}
      </Card>
    </Flex>
  );
}
