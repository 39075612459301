import axios from "./index";

class DiscountApi {
  static DiscountList = (data) => {
    return axios.post(`${base}/list`,{},{
      headers: { accessToken: data.token },
    })
  };
      static DiscountDelete = (data) => {
  const config = {
    headers: { accessToken: data.token },
    data: { id: data.id }
  };
  return axios.delete(`${base}/deletebyId`, config);
};
  static DiscountCreate = (data) => {
    let require={
     couponType:data.couponType,
     couponCode:data.couponCode,
     discountValue:data.discountValue,
     maxConsume:data.maxConsume,
     expireDate:data.expireDate,
     description:data.description,
    }
    return axios.post(`${base}/add`,require,{
      headers: { accessToken: data.token },
    });
  };
 static DiscountUpdate = (data) => {
    let require={
     couponType:data.couponType,
     couponCode:data.couponCode,
     discountValue:data.discountValue,
     maxConsume:data.maxConsume,
     expireDate:data.expireDate,
     description:data.description,
    }
    return axios.put(`${base}/edit/${data.id}`,require,{
      headers: { accessToken: data.token },
    });
  };


}

let base = "discount";

export default DiscountApi;