import axios from "./index";

class UserApi {
  static UserList = (data) => {
    return axios.get(`${base}/list`,{
      headers: { accessToken: data.token },
    })
  };
      static UserDelete = (data) => {
  const config = {
    headers: { accessToken: data.token },
    data: { id: data.id }
  };
  return axios.delete(`${base}/deletebyId`, config);
};
  static UserCreate = (data) => {
    let require={
     name:data.name,
     email:data.email,
     phoneNumber:data.phoneNumber,
     role:data.role
    }
    return axios.post(`${base}/add`,require,{
      headers: { accessToken: data.token },
    });
  };

 static UserUpdate = (data) => {
    let require={
     name:data.name,
     email:data.email,
     phoneNumber:data.phoneNumber,
     role:data.role
    }
    return axios.put(`${base}/edit/${data.id}`,require,{
      headers: { accessToken: data.token },
    });
  };

}

let base = "auth/admin";

export default UserApi;
