import axios from "./index";

class CourseApi {
  static CourseList = () => {
    return axios.post(`${base}/list`);
  };
  static CourseCreate = (data) => {
    let require = {
      courseId: data.id,
      coursetype: data.type,
      name: data.name,
      shortdescription: data.shortdescription,
      description: data.description,
      tags: data.tags,
      meta: data.meta,
      icon: data.icon,
      media: data.media,
      basePrice: data.basePrice,
      totalPrice: data.totalPrice,
      status: data.status,
      curriculum: data.section,
      objective: data.objectives,
      requirements: data.requirements,
      batch: [
        {
          type: "Weekdays",
          startDate: [data.batch1, data.batch2],
        },
        {
          type: "Weekends",
          startDate: [data.batch3, data.batch4],
        },
      ],
    };
    return axios.post(`${base}/admin/add`, require, {
      headers: { accessToken: data.token },
    });
  };
  static CourseAdmin = (data) => {
    const config = {
      headers: {
        accessToken: data.token,
      },
    };
    return axios.post(`${base}/admin/list`, {}, config);
  };
  static CoursePublish = (data) => {
    let req = {
      status: "PUBLISH",
    };
    return axios.patch(`${base}/admin/publish/${data.id}`, req, {
      headers: {
        accessToken: data.token,
      },
    });
  };
  static CourseLiveDelete = (data) => {
    const config = {
      headers: { accessToken: data.token },
      data: { id: data.id },
    };
    return axios.delete(`${base}/deletebyId`, config);
  };
  static CourseAdminDelete = (data) => {
    const config = {
      headers: { accessToken: data.token },
      data: { id: data.id },
    };
    return axios.delete(`${base}/admin/deletebyId`, config);
  };
  static CourseUpdate = (data) => {
    let require = {
      coursetype: data.type,
      name: data.name,
      shortdescription: data.shortdescription,
      description: data.description,
      tags: data.tags,
      icon: data.icon,
      media: data.media,
      meta: data.meta,
      basePrice: data.basePrice,
      totalPrice: data.totalPrice,
      status: data.status,
      curriculum: data.section,
      objectives: data.objectives,
      requirements: data.requirements,
      batch: [
        {
          type: "Weekdays",
          startDate: [data.batch1, data.batch2],
        },
        {
          type: "Weekends",
          startDate: [data.batch3, data.batch4],
        },
      ],
    };
    return axios.put(`${base}/admin/edit/${data.id}`, require, {
      headers: { accessToken: data.token },
    });
  };
  static CourseAdminEditData = (data) => {
    return axios.get(`${base}/admin/getbyId/${data.id}`, {
      headers: { accessToken: data.token },
    });
  };
  static CourseLiveEditData = (data) => {
    return axios.get(`${base}/getbyId/${data.id}`, {
      headers: { accessToken: data.token },
    });
  };
  static CourseStudentList = (data) => {
    let require = {
      groupBy: data.group,
      startDate: data.startDate,
      endDate: data.endDate,
      filter: {
        courseId: data.courseId,
      },
    };
    return axios.post(`${base}/admin-user-course`, require, {
      headers: { accessToken: data.token },
    });
  };
  static CourseTrafficList = (data) => {
  
    let require = {
      groupBy: data.group,
      startDate: data.startDate,
      endDate: data.endDate,
      filter: data.filter,
    };

    return axios.post(`/auth/getUserActivity`, require, {
      headers: { accessToken: data.token },
    });
  };
}
let base = "course";

export default CourseApi;
