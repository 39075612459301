import React from "react";
import { Input, Flex, Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";
const TagsInput = (props) => {
  const [tags, setTags] = React.useState([]);
  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      props.selectedTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };
  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };
  return (
    <>
      <Input
        type="text"
        onKeyUp={(event) => addTags(event)}
        placeholder="Press enter to add tags"
      />
      <Flex mt={5}>
        {tags.map((tag, index) => (
          <Tag variant="solid" colorScheme="purple" key={index} ml={2}>
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton onClick={() => removeTags(index)} />
          </Tag>
        ))}
      </Flex>
    </>
  );
};
export default TagsInput;
