import axios from "./index";

class ContentApi {
  static ContentHome = () => {
    return axios.get(`${base}/HomePage`);
  };
  static ContentAbout = () => {
    return axios.get(`${base}/AboutPage`);
  };
  static ContentChallenge = () => {
    return axios.get(`${base}/ChallengeVar`);
  };
   static ContentLFSM = () => {
    return axios.get(`${base}/LFSM`);
  };
  static UpdateChallenge = (data) => {
    let require = {
      contenttype: "ChallengeVar",
      contentAdmin: {
        list: data.inputValues,
      },
      status: "SENDFORAPPROVAL",
    };
    return axios.put(`content/admin/edit/${data.id}`, require, {
      headers: { accessToken: data.token },
    });
  };
    static UpdateLFSM = (data) => {
    let require = {
      contenttype: "LFSM",
      contentAdmin: {
        list: data.inputValues,
      },
      status: "SENDFORAPPROVAL",
    };
    return axios.put(`content/admin/edit/${data.id}`, require, {
      headers: { accessToken: data.token },
    });
  };
  static UpdateAbout = (data) => {
    let require = {
      contenttype: "AboutPage",
      contentAdmin: {
        title: data.title,
        subtitle: data.subtitle,
        description: data.description,
        list: data.inputValues,
      },
      status: "SENDFORAPPROVAL",
    };
    return axios.put(`content/admin/edit/${data.id}`, require, {
      headers: { accessToken: data.token },
    });
  };
  static UpdateHome = (data) => {
    let require = {
      contenttype: "HomePage",
      contentAdmin: [
        {
          section: 1,
          title: data.title,
          description: data.description,
        },
        {
          section: 2,
          prehead: data.prehead,
          head: data.head,
          benefits: data.benefits,
        },
        {
          section: 4,
          preTitle: data.preTitle,
          titlesec4: data.titlesec4,
          descriptionsec4: data.descriptionsec4,
          urlsec4: data.urlsec4,
        },
        {
          section: 5,
          videos: [
            {
              url1: data.url1,
            },
            {
              url2:data.url2,
            },
            {
              url3: data.url3,
            },
          ],
        },
        {
          section: 6,
          stats: {
            value1: data.value1,
            value2: data.value2,
            value3: data.value3,
            value4: data.value4,
          },
        },
      ],
      status: "SENDFORAPPROVAL",
    };
    return axios.put(`content/admin/edit/${data.id}`, require, {
      headers: { accessToken: data.token },
    });
  };
   static publishHome = (data) => {
    let require = {
    status:"PUBLISH"
    };
    return axios.patch(`/content/admin/publish/${data.id}`, require, {
      headers: { accessToken: data.token },
    });
  };
}

let base = "content/getbyContentType";

export default ContentApi;
