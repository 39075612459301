import axios from "./index";

class FaqApi {
  static FaqList = () => {
    return axios.post(`${base}/list`);
  };
  // static FaqDelete = (data) => {
  //   let require = {
  //     id: data.id,
  //   };
  //   return axios.delete(`${base}/deletebyId`, require, {
  //     headers: { accessToken: data.token },
  //   });
  // };
  static FaqDelete = (data) => {
  const config = {
    headers: { accessToken: data.token },
    data: { id: data.id }
  };
  return axios.delete(`${base}/deletebyId`, config);
};
    static FaqCreate = (data) => {
      let require={
        tags:data.tags,
        question:data.question,
        answer:data.answer,
      }
      return axios.post(`${base}/add`,require,{
        headers: { accessToken: data.token },
      });
    };
    static FaqUpdate = (data) => {
      let require={
        tags:data.tags,
        question:data.question,
        answer:data.answer,
      }
      return axios.put(`${base}/edit/${data.id}`,require,{
        headers: { accessToken: data.token },
      });
    };
  
}

let base = "faq";

export default FaqApi;
