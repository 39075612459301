import axios from "./index";

class BlogApi {
  static BlogList = () => {
    return axios.post(`${base}/list`);
  };
  static BlogAdmin = (data) => {
    const config = {
      headers: {
        accessToken: data.token,
      },
    };
    return axios.post(`${base}/admin/list`, {}, config);
  };
  static BlogPublish = (data) => {
    let req = {
      status: "PUBLISH",
    };
    return axios.patch(`${base}/admin/publish/${data.id}`, req, {
      headers: {
        accessToken: data.token,
      },
    });
  };
   static BlogLiveDelete = (data) => {
  const config = {
    headers: { accessToken: data.token },
    data: { id: data.id }
  };
  return axios.delete(`${base}/deletebyId`, config);
};
  static BlogAdminDelete = (data) => {
  const config = {
    headers: { accessToken: data.token },
    data: { id: data.id }
  };
  return axios.delete(`${base}/admin/deletebyId`, config);
};
  static BlogCreate = (data) => {
    let require = {
      type: data.type,
      title: data.title,
      titleDescription: data.titleDescription,
      titleUrl: data.titleUrl,
      tags: data.tags,
      content: data.content,
      status: data.status,
    };
    return axios.post(`${base}/admin/add`, require, {
      headers: { accessToken: data.token },
    });
  };
  static BlogEdit = (data) => {
    let require = {
      type: data.type,
      title: data.title,
      titleDescription: data.titleDescription,
      titleUrl: data.titleUrl,
      tags: data.tags,
      content: data.content,
      status: data.status,
      blogId: data.id,
    };
    return axios.post(`${base}/admin/add`, require, {
      headers: { accessToken: data.token },
    });
  };
  static BlogUpdate = (data) => {
    let require = {
      type: data.type,
      title: data.title,
      titleDescription: data.titleDescription,
      titleUrl: data.titleUrl,
      tags: data.tags,
      content: data.content,
      status: data.status,
      blogId: data.id,
    };
    return axios.put(`${base}/admin/edit/${data.id}`, require, {
      headers: { accessToken: data.token },
    });
  };
  static BlogAdminEditData = (data) => {
    return axios.get(`${base}/admin/getbyId/${data.id}`, {
      headers: { accessToken: data.token },
    });
  };
  static BlogLiveEditData = (data) => {
    return axios.get(`${base}/getbyId/${data.id}`, {
      headers: { accessToken: data.token },
    });
  };
}

let base = "blog";

export default BlogApi;
