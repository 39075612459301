import React, { useState } from "react";
import Card from "components/card/Card";
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import Home from "./components/home";
import About from "./components/about";
import Challenge from "./components/challenge";
import LFSM from "./components/lfsm"
export default function Content() {
    return (
    <>
      <Card
        direction="column"
        w="100%"
        px="20px"
        mt="7rem"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        
<Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>Home Page</Tab>
            <Tab>About Page</Tab>
            <Tab>Challenge Page</Tab>
            <Tab>LFSM</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Home/>
            </TabPanel>
            <TabPanel>
              <About/>
            </TabPanel>
              <TabPanel>
              <Challenge/>
            </TabPanel>
            <TabPanel>
              <LFSM/>
            </TabPanel>
          </TabPanels>
        </Tabs>


       



        
      </Card>
    </>
  );
}
