import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import ContentApi from "api/content";
import { EditIcon, CheckIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { useAuth } from "../../../../auth-context/auth.context";
export default function Home() {
  const toast = useToast();
  const { user } = useAuth();
  const [list, setList] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [id, setId] = useState();
  const [token, setToken] = useState(user.token);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [prehead, setPrehead] = useState("");
  const [head, setHead] = useState("");
  const [benefits, setBenefits] = useState([]);
  const [urlsec2, setUrlsec2] = useState("");
  const [preTitle, setPreTitle] = useState("");
  const [titlesec4, setTitlesec4] = useState("");
  const [descriptionsec4, setDescriptionsec4] = useState("");
  const [urlsec4, setUrlsec4] = useState("");
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [url3, setUrl3] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [loading, setLoading] = useState(false);
  const enable = () => {
    setDisabled(!disabled);
  };
  const disable = () => {
    setDisabled(true);
  };
  useEffect(async () => {
    try {
      setLoading(true);
      let response = await ContentApi.ContentHome();
      response = response.data;
      setLoading(false);
      let responseData = response.responseData;
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setId(responseData._id);
        setList(responseData.contentAdmin);
        const data = responseData.contentAdmin;
        setTitle(data[0].title);
        setDescription(data[0].description);
        setPrehead(data[1].prehead);
        setHead(data[1].head);
        setPreTitle(data[2].preTitle);
        setTitlesec4(data[2].titlesec4);
        setDescriptionsec4(data[2].descriptionsec4);
        setUrlsec4(data[2].urlsec4);
        setUrl1(data[3].videos[0].url1);
        setUrl2(data[3].videos[1].url2);
        setUrl3(data[3].videos[2].url3);
        setValue1(data[4].stats.value1);
        setValue2(data[4].stats.value2);
        setValue3(data[4].stats.value3);
        setValue4(data[4].stats.value4);
        const bendata = data[1].benefits;
        const inputValuesArray = bendata.map((value) => value);

        setBenefits(inputValuesArray);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);
  const handleInputChange = (index, value) => {
    setBenefits((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };
  const handlePublish = async () => {
    try {
      let response = await ContentApi.publishHome({
        id,
        token,
      });
      response = response.data;
      console.log(response);
      if (response.statusCode == 1) {
        toast({
          title: "Successfully Published",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      } else if (response.statusCode !== 1) {
        toast({
          title: "Error Publishing",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
    setDisabled(true);
  };
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="500">
          Home Page
        </Text>
        {disabled ? (
          <EditIcon ml="5" fontSize="large" onClick={enable} cursor="pointer" />
        ) : (
          <CheckIcon
            ml="5"
            fontSize="large"
            onClick={disable}
            cursor="pointer"
          />
        )}
      </Flex>
      {loading && (
        <Text fontSize="xl" fontWeight="700" mt={4} p={4}>
          Loading....
        </Text>
      )}
      {list.length > 0 ? (
        <>
          <Flex direction="column">
            <Text fontSize="xl" fontWeight="500" mt="5">
              Section 1
            </Text>

            <FormControl mt={5}>
              <FormLabel>Title</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="Text"
                  disabled={disabled}
                  defaultValue={title}
                  maxLength="100"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>SubTitle</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="Text"
                  disabled={disabled}
                  defaultValue={description}
                  maxLength="200"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
          </Flex>
          <Flex direction="column">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="xl" fontWeight="500" mt="5">
                Section 2
              </Text>
            </Flex>
            <FormControl mt={5}>
              <FormLabel>Title</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="Text"
                  disabled={disabled}
                  defaultValue={prehead}
                  maxLength="100"
                  onChange={(e) => {
                    setPrehead(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>SubTitle</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="Text"
                  disabled={disabled}
                  defaultValue={head}
                  maxLength="200"
                  onChange={(e) => {
                    setHead(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <Flex alignItems="center" justifyContent="space-between">
                <FormLabel>Benefits</FormLabel>
              </Flex>

              {benefits.map((ben, index) => {
                return (
                  <Flex alignItems="center" mt="2" key={index}>
                    <Input
                      type="text"
                      w="100%"
                      maxLength="70"
                      disabled={disabled}
                      defaultValue={ben}
                      onChange={(event) =>
                        handleInputChange(index, event.target.value)
                      }
                    />
                  </Flex>
                );
              })}
            </FormControl>
          </Flex>
          <Flex direction="column">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="xl" fontWeight="500" mt="5">
                Section 3
              </Text>
            </Flex>
            <FormControl mt={5}>
              <FormLabel>PreTitle</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="Text"
                  disabled={disabled}
                  defaultValue={preTitle}
                  maxLength="60"
                  onChange={(e) => {
                    setPreTitle(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Title</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="Text"
                  disabled={disabled}
                  defaultValue={titlesec4}
                  maxLength="100"
                  onChange={(e) => {
                    setTitlesec4(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Description</FormLabel>
              <Flex alignItems="center">
                <Textarea
                  disabled={disabled}
                  defaultValue={descriptionsec4}
                  maxLength="400"
                  onChange={(e) => {
                    setDescriptionsec4(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Url</FormLabel>
              <Flex alignItems="center">
                <Input
                  disabled={disabled}
                  defaultValue={urlsec4}
                  onChange={(e) => {
                    setUrlsec4(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
          </Flex>
          <Flex direction="column">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="xl" fontWeight="500" mt="5">
                Section 4
              </Text>
            </Flex>
            <FormControl mt={5}>
              <FormLabel>Link 1</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="text"
                  disabled={disabled}
                  defaultValue={url1}
                  onChange={(e) => {
                    setUrl1(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Link 2</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="text"
                  disabled={disabled}
                  defaultValue={url2}
                  onChange={(e) => {
                    setUrl2(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Link 3</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="text"
                  disabled={disabled}
                  defaultValue={url3}
                  onChange={(e) => {
                    setUrl3(e.target.value);
                  }}
                />
              </Flex>
            </FormControl>
          </Flex>
          <Flex direction="column">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="xl" fontWeight="500" mt="5">
                Section 5
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <FormControl mt={5} w="48%">
                <FormLabel>Enrolled Students</FormLabel>
                <Flex alignItems="center">
                  <Input
                    type="text"
                    disabled={disabled}
                    defaultValue={value1}
                    maxLength="20"
                    onChange={(e) => {
                      setValue1(e.target.value);
                    }}
                  />
                </Flex>
              </FormControl>
              <FormControl mt={5} w="48%">
                <FormLabel>Certified Students</FormLabel>
                <Flex alignItems="center">
                  <Input
                    type="text"
                    disabled={disabled}
                    defaultValue={value2}
                    maxLength="20"
                    onChange={(e) => {
                      setValue2(e.target.value);
                    }}
                  />
                </Flex>
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between">
              <FormControl mt={5} w="48%">
                <FormLabel>Session Finished</FormLabel>
                <Flex alignItems="center">
                  <Input
                    type="text"
                    disabled={disabled}
                    defaultValue={value3}
                    maxLength="20"
                    onChange={(e) => {
                      setValue3(e.target.value);
                    }}
                  />
                </Flex>
              </FormControl>
              <FormControl mt={5} w="48%">
                <FormLabel>Google Rating</FormLabel>
                <Flex alignItems="center">
                  <Input
                    type="text"
                    disabled={disabled}
                    defaultValue={value4}
                    maxLength="20"
                    onChange={(e) => {
                      setValue4(e.target.value);
                    }}
                  />
                </Flex>
              </FormControl>
            </Flex>
            <FormControl mt={5}>
              <Button
                colorScheme="blue"
                disabled={disabled}
                onClick={handlePublish}
                defaultValue={id}
              >
                Publish
              </Button>
            </FormControl>
          </Flex>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
}
