import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  useDisclosure,
  Button,
  Select,
  Flex,
  useToast,
} from "@chakra-ui/react";
import FaqApi from "api/faq";
import { useAuth } from "../../../../auth-context/auth.context";
export default function PopModal(props) {
  const { user } = useAuth();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [tags, setTags] = useState();
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [token, setToken] = useState(user.token);
  const AddQues = async (e) => {
    e.preventDefault();
    if (!question || !tags || !answer) {
      toast({
        title: "All fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        let response = await FaqApi.FaqCreate({
          tags,
          question,
          answer,
          token,
        });
        response = response.data;
        if (response.statusCode == 1) {
          toast({
            title: "Successfully Added",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          onClose();
          props.func();
        } else if (response.statusCode !== 1) {
          toast({
            title: "Error Adding",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
        // window.location.reload();
        // console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <Button onClick={onOpen}>{props.Icon}</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.head}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                placeholder="Select Type"
                onChange={(e) => setTags(e.target.value)}
              >
                <option value="general">General</option>
                <option value="webinar">Webinar</option>
                <option value="online course">Online Course</option>
                <option value="offline course">Offline Course</option>
                <option value="challenge">30 Days Challenge</option>
                <option value="payment">Payment</option>
              </Select>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Question</FormLabel>
              <Flex alignItems="center">
                <Textarea
                  type="Text"
                  placeholder="Upto 200 Characters"
                  maxLength="200"
                  onChange={(e) => setQuestion(e.target.value)}
                  required
                />
              </Flex>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Answer</FormLabel>
              <Flex alignItems="center">
                <Textarea
                  type="Text"
                  placeholder="Upto 400 Characters"
                  maxLength="400"
                  onChange={(e) => setAnswer(e.target.value)}
                  required
                />
              </Flex>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={AddQues}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
