import axios from "./index";

class AuthApi {
  static Login = (data) => {
    let request = {
      phoneNumberOrEmail: data.email,
      password: data.password,
    };
    return axios.post(`${base}/login`, request);
  };

  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };
   static UserData = (data) => {
    return axios.get(`${base}/userProfile`,{
      headers: {accessToken: `${data.token}` },
    });
  };
  static Profile = (data) => {
    let require = {
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      pic: data.pic,
      city: data.city,
       detailsJSON:{
            dob:data.dob,
            exp:data.exp
          },
      gender: data.gender,
      about:data.about
    };
    return axios.patch(`${base}/userProfile`,require,{
      headers: {accessToken: `${data.token}` },
    });
  };
  static Logout = (data) => {
    return axios.post(`${base}/logout`, data, {
      headers: { Authorization: `${data.token}` },
    });
  };
}

let base = "auth";

export default AuthApi;
