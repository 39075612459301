import React from "react";
import BlogAdminList from "./components/blogAdminList";
import BlogLiveList from "./components/blogLiveList";
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { Link, Route, Switch } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";
function Index() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        mt="7rem"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Blogs
          </Text>

          <Link to="/addblog">
            <AddIcon />
          </Link>
        </Flex>
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>Live Blogs</Tab>
            <Tab>Draft Blogs</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <BlogLiveList />
            </TabPanel>
            <TabPanel>
              <BlogAdminList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </>
  );
}

export default Index;
