// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useToast,
  Select,
} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import { MdOutlineCalendarToday } from "react-icons/md";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { useAuth } from "../../../../auth-context/auth.context";
export default function TotalSpent(props) {
  const { user } = useAuth();
  const { ...rest } = props;
  const toast = useToast();

  let rowNumber = 0;

  // Chakra Color Mode
  const lineChartDataTotalSpent = [
    {
      name: "Visit",
      data: props.chartvisit,
    },
    {
      name: "Students",
      data: props.chartstudent,
    },
  ];
  const lineChartOptionsTotalSpent = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF", "#39B8FF"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#7551FF",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      categories: props.chartdate,
    },

    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
    },
    color: ["#7551FF", "#39B8FF"],
  };
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      {props.data ? (
        <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
          <Flex flexDirection="column" me="20px" mt="28px">
            <Text
              color={textColor}
              fontSize="34px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
            >
              {props.visit}
            </Text>
            <Flex align="center" mb="20px">
              <Text
                color="secondaryGray.600"
                fontSize="sm"
                fontWeight="500"
                mt="4px"
                me="12px"
              >
                Total Traffic
              </Text>
            </Flex>

            <Text
              color={textColor}
              fontSize="34px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
            >
              {props.students}
            </Text>
            <Flex align="center" mb="20px">
              <Text
                color="secondaryGray.600"
                fontSize="sm"
                fontWeight="500"
                mt="4px"
                me="12px"
              >
                Total Conversion
              </Text>
            </Flex>
          </Flex>
          <Box minH="260px" minW="75%" mt="auto">
            {props.chartstudent && props.chartvisit ? (
              <ReactApexChart
                options={lineChartOptionsTotalSpent}
                series={lineChartDataTotalSpent}
                type="line"
                width="100%"
                height="100%"
              />
            ) : (
              <></>
            )}
          </Box>
        </Flex>
      ) : (
        <Flex height={"50vh"} justifyContent="center" alignItems="center">
          Please Enter Data To See Analytics
        </Flex>
      )}
    </Card>
  );
}
