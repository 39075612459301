import React, { useRef, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Flex,
  Text,
  useToast,
  FormHelperText,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import BlogApi from "../../../../api/blog";
import { AddIcon } from "@chakra-ui/icons";
import JoditEditor from "jodit-react";
import TagsInput from "./Tags";
import { useAuth } from "../../../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
export default function BlogAdd() {
  const history = useHistory();
  function handleClick() {
    history.push("admin/blogs");
  }
  const toast = useToast();
  const { user } = useAuth();
  const editor = useRef(null);
  const [picLoading, setPicLoading] = useState(false);
  const [picLoadingBlog, setPicLoadingBlog] = useState(false);
  const [type, setType] = useState("blog");
  const [title, setTitle] = useState();
  const [titleDescription, setTitleDescription] = useState();
  const [titleUrl, setTitleUrl] = useState();
  const [blogUrl, setBlogUrl] = useState("");
  const [tags, setTags] = useState([]);
  const [status, setStatus] = useState("DRAFT");
  const [content, setContent] = useState("");
  const [error, setError] = useState();
  const [token, setToken] = useState(user.token);
  let id = null;
  const handleFileInputChangeImage = (image) => {
    setPicLoading(true);
    if (image === undefined) {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (
      (image.type === "image/jpeg" || image.type === "image/png") &&
      image.size <= 10 * 1024 * 1024
    ) {
      const data = new FormData();
      data.append("file", image);
      fetch("https://api.stockventure.in/util/uploadmedia", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.responseData[0]);
          setTitleUrl(data.responseData[0]);
          setPicLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPicLoading(false);
        });
    } else {
      toast({
        title:
          "Please Select Proper Format and Size. (Only png and jpg are allowed and size limit is 10mb)",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setPicLoading(false);
      return;
    }
  };
  const handleFileInputChangeImageBlog = (image) => {
    setPicLoadingBlog(true);
    if (image === undefined) {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (
      (image.type === "image/jpeg" || image.type === "image/png") &&
      image.size <= 10 * 1024 * 1024
    ) {
      const data = new FormData();
      data.append("file", image);
      fetch("https://api.stockventure.in/util/uploadmedia", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.responseData[0]);
          setBlogUrl(data.responseData[0]);
          setPicLoadingBlog(false);
        })
        .catch((err) => {
          console.log(err);
          setPicLoadingBlog(false);
        });
    } else {
      toast({
        title:
          "Please Select Proper Format and Size. (Only png and jpg are allowed and size limit is 10mb)",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setPicLoadingBlog(false);
      return;
    }
  };
  const BlogDraft = async (e) => {
    e.preventDefault();
    if (
      !type ||
      !title ||
      !titleDescription ||
      !titleUrl ||
      !tags ||
      !content ||
      !status
    ) {
      toast({
        title: "All fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        let response = await BlogApi.BlogCreate({
          type,
          title,
          titleDescription,
          titleUrl,
          tags,
          content,
          status,
          token,
          id,
        });
        response = response.data;
        console.log(response);
        if (response.statusCode == 1) {
          toast({
            title: "Successfully Added",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          setTimeout(() => {
            history.push("admin/blogs");
          }, "1000");
        } else if (response.statusCode == 0) {
          let error = response.data.responseData.error.name;
          toast({
            title: error,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      } catch (err) {
        if (err.message) {
          return setError(err.message);
        }
        return setError("There has been an error.");
      }
    }
  };
  const selectedTags = (tags) => setTags(tags);
  return (
    <>
      <Flex mt={10}>
        <Card w="90%" margin="0 auto">
          <Flex pt="25px" justify="space-between" mb="20px" align="center">
            <Text fontSize="22px" fontWeight="700" lineHeight="100%">
              Add Blog
            </Text>
          </Flex>

          <FormControl mt={5}>
            <FormLabel>Title</FormLabel>
            <Input
              type="Text"
              placeholder="Upto 100 Characters"
              maxlength="100"
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </FormControl>
          <FormControl mt={5}>
            <FormLabel>Title Description</FormLabel>
            <Input
              type="text"
              placeholder="Upto 200 Characters"
              maxlength="200"
              required
              onChange={(e) => setTitleDescription(e.target.value)}
            />
          </FormControl>

          <FormControl mt={5}>
            <FormLabel>Image Url</FormLabel>
            <Input
              type="file"
              required
              accept="image/*"
              onChange={(e) => handleFileInputChangeImage(e.target.files[0])}
            />
            <FormHelperText>
              (Recommended Image Resolution 1280p X 720p and Size is 10mb)
            </FormHelperText>
            {picLoading && <div>Uploading....</div>}
          </FormControl>

          <FormControl mt={5}>
            <FormLabel>Tags</FormLabel>
            <TagsInput
              selectedTags={selectedTags}
              required
              onChange={(e) => setTags(e.target.value)}
            />
          </FormControl>
          <FormControl mt={5}>
            <FormLabel>Blog Image Upload</FormLabel>
            <Input
              type="file"
              required
              accept="image/*"
              onChange={(e) =>
                handleFileInputChangeImageBlog(e.target.files[0])
              }
            />
            <FormHelperText>
              (Recommended Image Resolution 1280p X 720p and Size is 10mb)
            </FormHelperText>
            {picLoadingBlog && <div>Uploading....</div>}
          </FormControl>
          <FormControl mt={5}>
            <FormLabel>Blog Image Url</FormLabel>
            <Input type="Text" defaultValue={blogUrl} required />
          </FormControl>
          <FormControl mt={5}>
            <JoditEditor
              ref={editor}
              value={content}
              tabIndex={1}
              onBlur={(newContent) => setContent(newContent)}
              onChange={(newContent) => setContent(newContent)}
            />
          </FormControl>
          <FormControl mt={5}>
            <Flex justify="flex-end" gap={30}>
              <Button variant="brand" onClick={BlogDraft}>
                Add
              </Button>
              <Button variant="brand" onClick={handleClick}>
                Cancel
              </Button>
            </Flex>
          </FormControl>
        </Card>
      </Flex>
    </>
  );
}
