import axios from "./index";

class FormApi {
  static FormList = (data) => {
    let require = {
      type: data.type,
    };
    return axios.post(`${base}/list`, require, {
      headers: { accessToken: data.token },
    });
  };
  static FormDelete = (data) => {
    const config = {
      headers: { accessToken: data.token },
      data: { id: data.id },
    };
    return axios.delete(`${base}/deletebyId`, config);
  };
    static FormStatus = (data) => {
      let require={
        status:data.status
      }
    return axios.put(`${base}/edit/${data.id}`,require, {
        headers: { accessToken: data.token },
      });
  };
  static FormPaymentStatus = (data) => {
      let require={
        details:{
          type:data.type,
          dateofstarting:data.date,
          paymentStatus:data.paymentStatus
        }
      }
    return axios.put(`${base}/edit/${data.id}`,require, {
        headers: { accessToken: data.token },
      });
  };
}

let base = "form";

export default FormApi;
