import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { EditIcon, CheckIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import ContentApi from "api/content";
import { useAuth } from "../../../../auth-context/auth.context";
export default function About() {
  const toast = useToast();
  const { user } = useAuth();
  const [disabled, setDisabled] = useState(true);
  // const [inputFieldsList, setInputFieldsList] = useState([{ value: "" }]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [inputValues, setInputValues] = useState([]);
  const [id, setId] = useState();
  const [token, setToken] = useState(user.token);
  const enable = () => {
    setDisabled(!disabled);
  };
  const disable = () => {
    setDisabled(true);
  };
  const [list, setList] = useState([]);
  // function handleClickList() {
  //   setInputFieldsList([...inputFieldsList, { value: "" }]);
  // }
  // const handleDeleteList = (index) => {
  //   const newInputFieldsList = [...inputFieldsList];
  //   newInputFieldsList.splice(index, 1);
  //   setInputFieldsList(newInputFieldsList);
  // };
  // function handleChangeList(i, event) {
  //   const values = [...inputFieldsList];
  //   values[i].value = event.target.value;
  //   setInputFieldsList(values);
  // }
  useEffect(async () => {
    try {
      let response = await ContentApi.ContentAbout();
      response = response.data;
      console.log(response);
      let responseData = response.responseData;

      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setId(responseData._id);
        setList(responseData.contentAdmin);
        const data = responseData.contentAdmin.list;
        const inputValuesArray = data.map((value) => value);
        setInputValues(inputValuesArray);
        setTitle(responseData.contentAdmin.title);
        setSubtitle(responseData.contentAdmin.subtitle);
        setDescription(responseData.contentAdmin.description);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleInputChange = (index, value) => {
    setInputValues((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };
  const handleUpdate = async () => {
    try {
      let response = await ContentApi.UpdateAbout({
        id,
        title,
        subtitle,
        description,
        inputValues,
        token,
      });
      response = response.data;
      console.log(response);
      if (response.statusCode === 1) {
        toast({
          title: "Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      } else if (response.statusCode !== 1) {
        let error = response.error.errorMessage;
        toast({
          title: error,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
    setDisabled(true);
  };
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mt={10}>
        <Text fontSize="2xl" fontWeight="500">
          About Page
        </Text>
        {disabled ? (
          <EditIcon ml="5" fontSize="large" onClick={enable} cursor="pointer" />
        ) : (
          <CheckIcon
            ml="5"
            fontSize="large"
            onClick={disable}
            cursor="pointer"
          />
        )}
      </Flex>
      {list != null || undefined ? (
        <>
          <Flex direction="column">
            <Text fontSize="xl" fontWeight="500" mt="5">
              Section 1
            </Text>

            <FormControl mt={5}>
              <FormLabel>Title</FormLabel>
              <Flex alignItems="center">
                <Input
                  type="Text"
                  disabled={disabled}
                  maxLength="100"
                  defaultValue={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>SubTitle</FormLabel>
              <Flex alignItems="center">
                <Textarea
                  type="Text"
                  disabled={disabled}
                  maxLength="200"
                  defaultValue={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Description</FormLabel>
              <Flex alignItems="center">
                <Textarea
                  disabled={disabled}
                  defaultValue={description}
                  maxLength="400"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <Flex alignItems="center" justifyContent="space-between">
                <FormLabel>List</FormLabel>
                {/* <AddIcon onClick={handleClickList} /> */}
              </Flex>
              {inputValues.map((list, index) => {
                return (
                  <Flex alignItems="center" mt="2" key={index}>
                    <Input
                      type="text"
                      w="100%"
                      disabled={disabled}
                      defaultValue={list}
                      onChange={(event) =>
                        handleInputChange(index, event.target.value)
                      }
                    />
                  </Flex>
                );
              })}
            </FormControl>
            <FormControl mt={5}>
              <Button
                colorScheme="blue"
                disabled={disabled}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </FormControl>
          </Flex>
        </>
      ) : (
        <div>Error in fetching</div>
      )}
    </>
  );
}
