import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useToast,
  Stack,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { DeleteIcon, EditIcon, AddIcon, ViewIcon } from "@chakra-ui/icons";
import PopDelete from "./deletePopover";
import { Link } from "react-router-dom";
import BlogApi from "api/blog";
// Custom components
import { useAuth } from "../../../../auth-context/auth.context";
export default function CheckTable(props) {
  const toast = useToast();
  const { user } = useAuth();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [list, setList] = useState([]);
  const [token, setToken] = useState(user.token);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const fetchlist = async () => {
    try {
      setLoading(true);
      let response = await BlogApi.BlogList();
      response = response.data;
      setLoading(false);
      let responseData = response.responseData;
      // console.log(responseData)
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setList(responseData.reverse());
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchlist();
  }, []);
  useEffect(() => {
    if (list) {
      setTotalPages(Math.ceil(list.length / itemsPerPage));
    }
  }, [list, itemsPerPage]);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Button
        key={i}
        size="sm"
        variant="ghost"
        colorScheme={i === currentPage ? "blue" : undefined}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Button>
    );
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = list.slice(startIndex, endIndex);
  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const id = [e.target.value];
      const response = await BlogApi.BlogLiveDelete({ id, token });
      // console.log(id);
      console.log(response.data);
      if (response.data.statusCode == 1) {
        toast({
          title: "Successfully Deleted",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        fetchlist();
      } else if (response.data.statusCode !== 1) {
        toast({
          title: "Error Deleting",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Id
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Status
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Title
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor} maxWidth="30%">
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Title Desccription
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Auther
              </Flex>
            </Th>
            <Th pe="10px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Actions
              </Flex>
            </Th>
          </Tr>
        </Thead>
        {loading && (
          <Text fontSize="xl" fontWeight="700" mt={4} p={4}>
            Loading....
          </Text>
        )}
        <Tbody>
          {currentItems.map((list, index) => {
            return (
              <Tr key={list._id}>
                <Td>
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {startIndex + index + 1}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {list.status}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                      noOfLines={1}
                    >
                      {list.title}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                    noOfLines={1}
                  >
                    {list.titleDescription}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                    noOfLines={1}
                  >
                    {list.authorId.name}
                  </Text>
                </Td>
                <Td>
                  <Flex>
                    {/* <ViewIcon w={8} h={8} marginRight={5} alignItems="center" /> */}
                    <Link to={`/editliveblog/${list._id}`}>
                      {" "}
                      <EditIcon w={6} h={6} marginRight={5} />{" "}
                    </Link>
                    <PopDelete
                      Icon={<DeleteIcon />}
                      button={
                        <Button
                          colorScheme="red"
                          onClick={handleDelete}
                          value={list._id}
                        >
                          Delete
                        </Button>
                      }
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Stack direction="row" spacing={2} mt={4} justifyContent="center">
        <Box>
          <Button
            size="sm"
            isDisabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            Previous
          </Button>
        </Box>

        {pageItems}

        <Box>
          <Button
            size="sm"
            isDisabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </Button>
        </Box>
      </Stack>
    </>
  );
}
