import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useToast,
  Stack,Box
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { DeleteIcon, EditIcon, AddIcon, ViewIcon } from "@chakra-ui/icons";

import { Link, Route, Switch } from "react-router-dom";
import CourseApi from "api/course";
// Custom components
import Card from "components/card/Card";
import { useAuth } from "../../../auth-context/auth.context";
export default function Index(props) {
  const toast = useToast();
  const { user } = useAuth();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [list, setList] = useState([]);
  const [token, setToken] = useState(user.token);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
    const fetch=async ()=>{
   try {
      setLoading(true);
      let response = await CourseApi.CourseAdmin({ token });
      response = response.data;
      setLoading(false);
      let responseData = response.responseData;
      const filteredDraft = responseData.filter(
        (item) => item.status === "DRAFT" || item.status === "SENDFORAPPROVAL"
      );
      console.log(filteredDraft);
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setList(filteredDraft.reverse());
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(()=>{
  fetch()
  },[]);
    useEffect(() => {
    if (list) {
      setTotalPages(Math.ceil(list.length / itemsPerPage));
    }
  }, [list, itemsPerPage]);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Button
        key={i}
        size="sm"
        variant="ghost"
        colorScheme={i === currentPage ? "blue" : undefined}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Button>
    );
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = list.slice(startIndex, endIndex);
  const handlePublish = async (e) => {
    e.preventDefault();
    try {
      const id = e.target.value;
      let response = await CourseApi.CoursePublish({ id, token });
      response = response.data;
      console.log(response);
      if (response.statusCode === 1) {
        toast({
          title: "Successfully Published",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        fetch()
      } else if (response.statusCode !== 1) {
        toast({
          title: "Error Publishing",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        mt="7rem"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Course Publish
          </Text>
        </Flex>

        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr>
              <Th pe="10px" borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Id
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Status
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor} w="20%">
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Name
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor} w="40%">
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Desccription
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Auther
                </Flex>
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  Actions
                </Flex>
              </Th>
            </Tr>
          </Thead>
          {loading && (
            <Text fontSize="xl" fontWeight="700" mt={4} p={4}>
              Loading....
            </Text>
          )}
          <Tbody>
            {currentItems.map((list, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    <Flex align="center">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {startIndex + index + 1}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex align="center">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {list.status}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex align="center">
                      <Text
                        me="10px"
                        color={textColor}
                        fontSize="sm"
                        fontWeight="700"
                        noOfLines={2}
                      >
                        {list?.name}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Text
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                      noOfLines={2}
                    >
                      {list?.description}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                      noOfLines={1}
                    >
                      {list?.name}
                    </Text>
                  </Td>
                  <Td>
                    <Button
                      variant="brand"
                      fontSize="sm"
                      fontWeight="700"
                      value={list._id}
                      onClick={handlePublish}
                    >
                      Publish
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Stack direction="row" spacing={2} mt={4} justifyContent="center">
          <Box>
            <Button
              size="sm"
              isDisabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
            >
              Previous
            </Button>
          </Box>

          {pageItems}

          <Box>
            <Button
              size="sm"
              isDisabled={currentPage === totalPages}
              onClick={() => onPageChange(currentPage + 1)}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </Card>
    </>
  );
}
