// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Select,
  useToast,
} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
// Custom components
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
import { MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { format } from "date-fns";
import CourseApi from "../../../../api/course";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { useAuth } from "../../../../auth-context/auth.context";
export default function TotalSpent(props) {
  const { user } = useAuth();
  const toast = useToast();
  const { ...rest } = props;
  const [showPicker, setShowPicker] = useState(false);
  const [students, setStudents] = useState("");
  const [price, setPrice] = useState("");
  const [chartPrice, setChartPrice] = useState([]);
  const [chartStudent, setChartStudent] = useState([]);
  const [data, setData] = useState();
  const [courseId, setCourseId] = useState();
  let rowNumber = 0;

  const lineChartDataTotalSpent = [
    {
      name: "Revenue",
      data: props.chartprice,
    },
    {
      name: "Students",
      data: props.chartstudent,
    },
  ];
  const lineChartOptionsTotalSpent = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF", "#39B8FF"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#7551FF",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "category",
      categories: props.chartdate,
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
    },
    color: ["#7551FF", "#39B8FF"],
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      {props.data ? (
        <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
          <Flex flexDirection="column" me="20px" mt="28px">
            <Text
              color={textColor}
              fontSize="34px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
            >
              Rs.{props.price}
            </Text>
            <Flex align="center" mb="20px">
              <Text
                color="secondaryGray.600"
                fontSize="sm"
                fontWeight="500"
                mt="4px"
                me="12px"
              >
                Total Revenue
              </Text>
            </Flex>

            <Text
              color={textColor}
              fontSize="34px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
            >
              {props.students}
            </Text>
            <Flex align="center" mb="20px">
              <Text
                color="secondaryGray.600"
                fontSize="sm"
                fontWeight="500"
                mt="4px"
                me="12px"
              >
                Total Students
              </Text>
            </Flex>
          </Flex>
          <Box minH="260px" minW="75%" mt="auto">
            {props.chartprice && props.chartstudent && props.chartdate ? (
              <ReactApexChart
                options={lineChartOptionsTotalSpent}
                series={lineChartDataTotalSpent}
                type="line"
                width="100%"
                height="100%"
              />
            ) : (
              <></>
            )}
          </Box>
        </Flex>
      ) : (
        <Flex height={"50vh"} justifyContent="center" alignItems="center">
          Please Enter Data To See Analytics
        </Flex>
      )}
    </Card>
  );
}
