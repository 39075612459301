import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Stack,
  Box,
  useToast,
  Select,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import PopDelete from "./deletePopover";
import { DeleteIcon } from "@chakra-ui/icons";
// Custom components
import FormApi from "api/form";
import Modal from "./modal";
import { DownloadIcon } from "@chakra-ui/icons";
import { useAuth } from "../../../../auth-context/auth.context";
export default function ContactUs(props) {
  const { user } = useAuth();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(user.token);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const toast = useToast();
  const fetchList = async () => {
    try {
      setLoading(true);
      let type = "ContactUs";
      let response = await FormApi.FormList({
        type,
        token,
      });
      response = response.data;
      setLoading(false);
      let responseData = response.responseData;
      console.log(responseData);
      if (response.statusCode !== 1 && response.error) {
        setList(null);
      } else {
        setList(responseData.reverse());
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(async () => {
    fetchList();
  }, []);
  useEffect(() => {
    if (list) {
      setTotalPages(Math.ceil(list.length / itemsPerPage));
    }
  }, [list, itemsPerPage]);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Button
        key={i}
        size="sm"
        variant="ghost"
        colorScheme={i === currentPage ? "blue" : undefined}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Button>
    );
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = list.slice(startIndex, endIndex);

  const handleDelete = async (e) => {
    try {
      const id = [e.target.value];
      const response = await FormApi.FormDelete({ id, token });
      console.log(response.data);
      if (response.data.statusCode === 1) {
        toast({
          title: "Successfully Deleted",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      } else if (response.data.statusCode !== 1) {
        toast({
          title: "Error Deleting",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
      fetchList();
    } catch (err) {
      console.log(err);
    }
  };

  const handleStatusChange = async (status, id) => {
    try {
      let response = await FormApi.FormStatus({
        id,
        token,
        status,
      });
      response = response.data;
      let responseData = response.responseData;
      if (response.statusCode === 1) {
        toast({
          title: "Status Changed",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        fetchList();
      } else if (response.statusCode !== 1) {
        toast({
          title: "Error",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {loading && (
        <Text fontSize="xl" fontWeight="700" mt={4} p={4}>
          Loading....
        </Text>
      )}
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            <Th p="8px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Id
              </Flex>
            </Th>
            <Th p="8px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Name
              </Flex>
            </Th>
            <Th p="8px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Email
              </Flex>
            </Th>
            <Th p="8px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Mobile
              </Flex>
            </Th>
            <Th p="8px" borderColor={borderColor} w="20%">
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Query
              </Flex>
            </Th>
            <Th p="8px" borderColor={borderColor} w="20%">
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                View
              </Flex>
            </Th>
            <Th p="8px" borderColor={borderColor} w="50%">
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Status
              </Flex>
            </Th>
            <Th p="8px" borderColor={borderColor}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "10px", lg: "12px" }}
                color="gray.400"
              >
                Actions
              </Flex>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {currentItems.map((contact, index) => {
            return (
              <Tr height="10px" overflow="hidden" key={contact._id}>
                <Td p="8px">
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {startIndex + index + 1}
                    </Text>
                  </Flex>
                </Td>

                <Td p="8px">
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                    >
                      {contact.name}
                    </Text>
                  </Flex>
                </Td>
                <Td p="8px">
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                      noOfLines={1}
                    >
                      {contact.email}
                    </Text>
                  </Flex>
                </Td>
                <Td p="8px">
                  <Flex align="center">
                    <Text
                      me="10px"
                      color={textColor}
                      fontSize="sm"
                      fontWeight="700"
                    >
                      {contact.phoneNumber}
                    </Text>
                  </Flex>
                </Td>
                <Td p="8px">
                  <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                    noOfLines={1}
                  >
                    {contact.content
                      ? contact.content.query
                        ? contact.content.query.slice(0, 10)
                        : "No queries"
                      : "No query found"}
                  </Text>
                </Td>
                <Td p="8px">
                  <Modal
                    Icon={<Button variant="brand">Details</Button>}
                    name={contact.name}
                    email={contact.email}
                    mobile={contact.phoneNumber}
                    query={
                      contact.content
                        ? contact.content.query
                          ? contact.content.query
                          : "No queries"
                        : "No query found"
                    }
                    download={
                      <>
                        {contact.content ? (
                          contact.content.pic ? (
                            <a
                              href={
                                contact.content
                                  ? contact.content.pic
                                    ? contact.content.pic
                                    : "No queries"
                                  : "No query found"
                              }
                              download={"Pic"}
                            >
                              <DownloadIcon />
                            </a>
                          ) : (
                            "No attachment found"
                          )
                        ) : (
                          "No attachment found"
                        )}
                      </>
                    }
                  />
                </Td>
                <Td w="30%" p="8px">
                  <Select
                    placeholder="Select Type"
                    onChange={(e) =>
                      handleStatusChange(e.target.value, contact._id)
                    }
                    defaultValue={contact.status}
                  >
                    <option value="Start">Started</option>
                    <option value="Done">Completed</option>
                    <option value="InProgress">Pending</option>
                  </Select>
                </Td>
                <Td p="8px">
                  <PopDelete
                    Icon={<DeleteIcon />}
                    button={
                      <Button
                        colorScheme="red"
                        onClick={handleDelete}
                        value={contact._id}
                      >
                        Delete
                      </Button>
                    }
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Stack direction="row" spacing={2} mt={4} justifyContent="center">
        <Box>
          <Button
            size="sm"
            isDisabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            Previous
          </Button>
        </Box>

        {pageItems}

        <Box>
          <Button
            size="sm"
            isDisabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </Button>
        </Box>
      </Stack>
    </>
  );
}
