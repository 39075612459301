import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RTLLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { AuthProvider } from "./auth-context/auth.context";
import { ProtectedRoute } from "./layouts/protected.route.js";
import AddBlog from "views/admin/blogs/components/blogAdd";
import EditAdminBlog from "views/admin/blogs/components/blogAdminEdit";
import EditLiveBlog from "views/admin/blogs/components/blogLiveEdit";
import EditAdminCourse from "views/admin/courses/components/editAdminCourse";
import EditLiveCourse from "views/admin/courses/components/editLiveCourse";
import AddCourse from "views/admin/courses/components/addCourse";
import Performance from "views/admin/courses/components/performance";
import Instructor from "views/admin/instructor";
import SignIn from "views/auth/signIn/index.jsx";
import ErrorBoundary from './ErrorBoundary'
let user = localStorage.getItem("user");
user = JSON.parse(user);
ReactDOM.render(
  <ErrorBoundary>
  <ChakraProvider theme={theme}>
    <AuthProvider userData={user}>
      <React.StrictMode>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <ProtectedRoute path={`/admin`} component={AdminLayout} />
            {/* <Route exact path="/blog" component={BlogList}/> */}
            <Route path="/addblog" component={AddBlog} />
            <Route path="/editadminblog/:editId" component={EditAdminBlog} />
            <Route path="/editliveblog/:editId" component={EditLiveBlog} />
            <Route path="/signin" component={SignIn} />
            <Route path="/addcourse" component={AddCourse} />
            <Route path="/editadmincourse/:editId" component={EditAdminCourse} />
            <Route path="/editlivecourse/:editId" component={EditLiveCourse} />
            <Route path="/performance" component={Performance} />
            <Route path="/instructor" component={Instructor} />
            {/* <ProtectedRoute path={`/rtl`} component={RTLLayout} /> */}
            <Redirect from="/" to="/admin/dashboards" />
          </Switch>
        </HashRouter>
      </React.StrictMode>
    </AuthProvider>
  </ChakraProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
