import React, { useRef, useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Text,
  Flex,
  useToast,
  Tag,
  TagLabel,
  TagCloseButton,
  FormHelperText,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import BlogApi from "../../../../api/blog";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
export default function BlogEdit() {
  const history = useHistory();
  function handleClick() {
    history.push("/admin/blogs");
  }
  const toast = useToast();
  const { user } = useAuth();
  const params = useParams();
  let id = params.editId;
  const editor = useRef(null);
  const [type, setType] = useState("blog");
  const [title, setTitle] = useState();
  const [titleDescription, setTitleDescription] = useState();
  const [titleUrl, setTitleUrl] = useState();
  const [blogUrl, setBlogUrl] = useState("");
  const [picLoading, setPicLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [picLoadingBlog, setPicLoadingBlog] = useState(false);
  const [status, setStatus] = useState("DRAFT");
  const [content, setContent] = useState("");
  const [editData, setEditData] = useState();
  const [error, setError] = useState();
  const [token, setToken] = useState(user.token);
  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      selectedTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };
  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };
  const handleFileInputChangeImage = (image) => {
    setPicLoading(true);
    if (image === undefined) {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (
      (image.type === "image/jpeg" || image.type === "image/png") &&
      image.size <= 10 * 1024 * 1024
    ) {
      const data = new FormData();
      data.append("file", image);
      fetch("https://api.stockventure.in/util/uploadmedia", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.responseData[0]);
          setTitleUrl(data.responseData[0]);
          setPicLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPicLoading(false);
        });
    } else {
      toast({
        title:
          "Please Select Proper Format and Size. (Only png and jpg are allowed and size limit is 10mb)",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setPicLoading(false);
      return;
    }
  };
  const handleFileInputChangeImageBlog = (image) => {
    setPicLoadingBlog(true);
    if (image === undefined) {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (
      (image.type === "image/jpeg" || image.type === "image/png") &&
      image.size <= 10 * 1024 * 1024
    ) {
      const data = new FormData();
      data.append("file", image);
      fetch("https://api.stockventure.in/util/uploadmedia", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.responseData[0]);
          setBlogUrl(data.responseData[0]);
          setPicLoadingBlog(false);
        })
        .catch((err) => {
          console.log(err);
          setPicLoadingBlog(false);
        });
    } else {
      toast({
        title:
          "Please Select Proper Format and Size. (Only png and jpg are allowed and size limit is 10mb)",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setPicLoadingBlog(false);
      return alert(
        "Please Select Proper Format and Size. (Only png and jpg are allowed and size limit is 10mb)"
      );
    }
  };
  useEffect(async () => {
    try {
      let response = await BlogApi.BlogLiveEditData({
        id,
        token,
      });
      response = response.data;

      console.log(response);
      if (response.statusCode !== 1 && response.error) {
        return setError(response.error.errorMessage);
      } else {
        setEditData(response.responseData);
        setTitle(response.responseData.title);
        setTitleDescription(response.responseData.titleDescription);
        setContent(response.responseData.content);
        setTitleUrl(response.responseData.titleUrl);
        setTags(response.responseData.tags);
      }
    } catch (err) {
      if (err.message) {
        return setError(err.message);
      }
      return setError("There has been an error.");
    }
  }, []);
  const BlogEdit = async (e) => {
    e.preventDefault();
    // setStatus("draft")
    if (
      !type ||
      !title ||
      !titleDescription ||
      !titleUrl ||
      !tags ||
      !content ||
      !status
    ) {
      toast({
        title: "All fields are required",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } else {
      try {
        let response = await BlogApi.BlogEdit({
          type,
          title,
          titleDescription,
          titleUrl,
          tags,
          content,
          status,
          token,
          id,
        });
        response = response.data;
        console.log(response);
        if (response.statusCode == 1) {
          toast({
            title: "Successfully Updated",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          setTimeout(() => {
            history.push("/admin/blogs");
          }, "1000");
        } else if (response.statusCode == 0) {
          let error = response.data.responseData.error.name;
          toast({
            title: error,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      } catch (err) {
        if (err.message) {
          return setError(err.message);
        }
        return setError("There has been an error.");
      }
    }
  };
  const selectedTags = (tags) => console.log(tags);
  return (
    <>
      {/* {console.log(editData)} */}
      {editData !== undefined ? (
        <Flex mt={10}>
          <Card w="90%" margin="0 auto">
            <Flex pt="25px" justify="space-between" mb="20px" align="center">
              <Text fontSize="22px" fontWeight="700" lineHeight="100%">
                Edit Blog
              </Text>
            </Flex>
            <FormControl mt={5}>
              <FormLabel>Title</FormLabel>
              <Input
                type="Text"
                placeholder="Upto 100 Characters"
                maxlength="100"
                defaultValue={editData.title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Title Description</FormLabel>
              <Input
                type="text"
                defaultValue={editData.titleDescription}
                placeholder="Upto 200 Characters"
                maxlength="200"
                required
                onChange={(e) => setTitleDescription(e.target.value)}
              />
            </FormControl>

            <FormControl mt={5}>
              <FormLabel>Image Url</FormLabel>
              <img
                src={editData.titleUrl}
                alt="blogimage"
                style={{ height: "15rem", width: "15rem" }}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Change Image</FormLabel>
              <Input
                type="file"
                required
                accept="image/*"
                onChange={(e) => handleFileInputChangeImage(e.target.files[0])}
              />
              <FormHelperText>
                (Recommended Image Resolution 1280p X 720p and Size is 10mb)
              </FormHelperText>
              {picLoading && <div>Uploading....</div>}
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Tags</FormLabel>
              <Input
                type="text"
                onKeyUp={(event) => addTags(event)}
                placeholder="Press enter to add tags"
              />
              <Flex mt={5}>
                {tags.map((tag, index) => (
                  <Tag variant="solid" colorScheme="purple" key={index} ml={2}>
                    <TagLabel>{tag}</TagLabel>
                    <TagCloseButton onClick={() => removeTags(index)} />
                  </Tag>
                ))}
              </Flex>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Blog Image Upload</FormLabel>
              <Input
                type="file"
                required
                accept="image/*"
                onChange={(e) =>
                  handleFileInputChangeImageBlog(e.target.files[0])
                }
              />
              <FormHelperText>
                (Recommended Image Resolution 1280p X 720p and Size is 10mb)
              </FormHelperText>
              {picLoadingBlog && <div>Uploading....</div>}
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Blog Image Url</FormLabel>
              <Input type="Text" defaultValue={blogUrl} required />
            </FormControl>
            <FormControl mt={5}>
              <JoditEditor
                ref={editor}
                value={editData.content}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => setContent(newContent)}
              />
            </FormControl>
            <FormControl mt={5}>
              <Flex justify="flex-end" gap={30}>
                <Button variant="brand" onClick={BlogEdit}>
                  Save
                </Button>
                <Button variant="brand" onClick={handleClick}>
                  Cancel
                </Button>
              </Flex>
            </FormControl>
          </Card>
        </Flex>
      ) : (
        <Text mt={200}>{error}</Text>
      )}
    </>
  );
}
