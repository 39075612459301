import React from 'react'
// import {
//     columnsDataCheck,
//   } from "views/admin/users/variables/columnsData";
  import UserTable from "views/admin/users/components/CheckTable";
// import tableDataCheck from "views/admin/users/variables/tableDataCheck.json";
const index = () => {
  return (
    <div>
        <UserTable  />
    </div>
  )
}

export default index